/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useJobAdsQuery } from "../job-ads/JobAdAPI";
import { useFormatDate } from "../shared/useFormatDate";
import { useSearchParams } from "../shared/useSearchParams";
import "twin.macro";
import { Skeleton } from "../shared/Skeleton";
import { PaginationWithNumber } from "../shared/PaginationWithNumber";
import { JobAdStatusBadge } from "../job-ads/JobAdListRoute";
import { JobAdExtensionCandidateCountHome } from "./Home";
import { useEffect, useState } from "react";
import { useJobAdCandidatesCountersFromESQuery } from "../job-ads/JobAdAPI"

export const AnnouncementsTable = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const formatDate = useFormatDate({ dateStyle: "medium" });

  // Get jobAds with paging
  const { page = 1, size = 10 } = useSearchParams();
  // Get all the jobAds (MULTIPOSTED and SCHOOL)
  const { status: jobAdsCreatedByUserStatus, data: jobAdsCreatedByUser } = useJobAdsQuery({
    status: ['PUBLISHED'],
    page: page - 1,
    size,
  });
  // Get organization PREMIUM or not
  const handleRowClick = (jobAd) => {
    if (jobAd.jobAdType === "SCHOOL") {
      history.push(`/job-ads-school/${jobAd.uuid}`);
    } else {
      history.push(`/job-ads/${jobAd.uuid}`);
    }
  };

  //Number of items mapped during the loading
  const loadingjobAdsCreatedByUser = 10;

  const [jobAdExtensions, setJobAdExtensions] = useState([]);
  const { mutate: getJobAdCandidateCountersFromES, status: mutationStatus, error } = useJobAdCandidatesCountersFromESQuery();
  useEffect(() => {
    const fetchJobAdExtensions = async () => {
      if (jobAdsCreatedByUser && jobAdsCreatedByUser.content && jobAdsCreatedByUser.content.length > 0) {
        const uuids = jobAdsCreatedByUser.content.map(jobAd => jobAd.uuid);
        try {
          getJobAdCandidateCountersFromES({
            uuids
          }, {
            onSuccess: (extensions) => {
              setJobAdExtensions(extensions);
            }
          });
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchJobAdExtensions();
  }, [jobAdsCreatedByUser, getJobAdCandidateCountersFromES]);

  const getJobAdExtensionForJobAd = (jobAdId) => {
    return jobAdExtensions.find((extension) => extension.uuid === jobAdId);
  };

  return (
    <>
      {jobAdsCreatedByUserStatus === "loading" && (
        <div tw="mb-14">
          <h1 tw="text-2xl font-extrabold text-gray-900 mt-20">{t("home.announcements")}</h1>
          <div tw="flex flex-col mt-4">
            <div tw="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div tw="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div tw="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table tw="min-w-full divide-y divide-gray-200">
                    <thead tw="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("home.announcements-title")}
                        </th>
                        <th
                          scope="col"
                          tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("home.announcements-date")}
                        </th>
                        <th
                          scope="col"
                          tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("home.announcements-status")}
                        </th>
                        <th
                          scope="col"
                          tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("home.announcements-candidates")}
                        </th>
                        <th
                          scope="col"
                          tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("home.announcements-selected")}
                        </th>
                      </tr>
                    </thead>

                    <tbody tw="bg-white divide-y divide-gray-200">
                      {[...Array(loadingjobAdsCreatedByUser)].map((e, index) => (
                        <tr key={index} tw="hover:cursor-pointer">
                          <td tw="px-6 py-4 whitespace-nowrap">
                            <div tw="flex items-center">
                              <div tw="text-sm text-gray-500">
                                <Skeleton />
                              </div>
                            </div>
                          </td>
                          <td tw="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                            <Skeleton />
                          </td>
                          <td tw="px-6 py-4 whitespace-nowrap">
                            <Skeleton />
                          </td>
                          <td tw="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <Skeleton />
                          </td>
                          <td tw="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <Skeleton />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <PaginationWithNumber
                    page={Number(page)}
                    pageSize={Number(size)}
                    totalCount={
                      jobAdsCreatedByUser ? jobAdsCreatedByUser.totalElements : Number(size)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {jobAdsCreatedByUserStatus === "success" &&
        jobAdsCreatedByUser &&
        jobAdsCreatedByUser.content.length !== 0 && (
          <div tw="mb-14">
            <h1 tw="text-2xl font-extrabold text-gray-900 mt-20">{t("home.announcements")}</h1>
            <div tw="flex flex-col mt-4">
              <div tw="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div tw="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div tw="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table tw="min-w-full divide-y divide-gray-200">
                      <thead tw="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("home.announcements-title")}
                          </th>
                          <th
                            scope="col"
                            tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("home.announcements-date")}
                          </th>
                          <th
                            scope="col"
                            tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("home.announcements-status")}
                          </th>
                          <th
                            scope="col"
                            tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("home.announcements-candidates")}
                          </th>
                          <th
                            scope="col"
                            tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("home.announcements-selected")}
                          </th>
                        </tr>
                      </thead>

                      <tbody tw="bg-white divide-y divide-gray-200">
                        {jobAdsCreatedByUser &&
                          jobAdsCreatedByUser.content.map((jobAd, index) => (
                            <tr
                              onClick={() => handleRowClick(jobAd)}
                              key={index}
                              tw="hover:cursor-pointer"
                            >
                              <td tw="px-6 py-4 whitespace-nowrap">
                                <div tw="flex items-center">
                                  {jobAd.title ? (
                                    <div tw="text-sm text-gray-500">{jobAd.title}</div>
                                  ) : (
                                    <div tw="text-sm text-gray-500">{t("home.untitled")}</div>
                                  )}
                                </div>
                              </td>
                              <td tw="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                                {formatDate(new Date(jobAd.creationDate))}
                              </td>
                              <td tw="px-6 py-4 whitespace-nowrap">
                                <JobAdStatusBadge status={jobAd.status} />
                              </td>
                              <JobAdExtensionCandidateCountHome
                                jobAdId={jobAd.uuid}
                                jobAdExtension={getJobAdExtensionForJobAd(jobAd.uuid)}
                              />
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <PaginationWithNumber
                      page={Number(page)}
                      pageSize={Number(size)}
                      totalCount={
                        jobAdsCreatedByUser ? jobAdsCreatedByUser.totalElements : Number(size)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
