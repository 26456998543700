import styled from "@emotion/styled";
import tw from "twin.macro";

export const Table = styled("table")(tw`min-w-full h-px`);

export const TableHead = styled("thead")();

export const TableRow = styled("tr")();

export const TableHeading = styled("th")(
  tw`py-4 px-6 text-sm font-medium text-gray-900 text-left whitespace-nowrap`
);
TableHeading.defaultProps = {
  scope: "col",
};

export const TableBody = styled("tbody")(tw`border-t border-gray-300 divide-y divide-gray-200`);

export const TableSubheading = styled("th")(
  tw`bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left whitespace-nowrap`
);
TableSubheading.defaultProps = {
  scope: "colgroup",
};

export const TableRowLabel = styled("th")(
  tw`py-3 pr-2 text-sm font-normal text-gray-500 text-left whitespace-nowrap`
);
TableRowLabel.defaultProps = {
  scope: "row",
};

export const TableCell = styled("td")(tw`py-3 px-6 text-sm text-gray-600 whitespace-nowrap`);
