/** @jsxImportSource @emotion/react */
import { ChatAlt2Icon, DocumentDownloadIcon, AnnotationIcon, UploadIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "twin.macro";
import { Button, PrimaryButton } from "../shared/Button";
import { useDisclosure } from "../shared/useDisclosure";
import {
  useCandidatePictureURLQuery,
  useCandidateQuestionnaireResultsQuery,
  useCandidateVideoURLQuery,
  useCandidatesOrganizationNoteQuery,
  useCandidateResumeMutation,
  useIntelli7ExportMutation,
  useCandidateUnreadMessagesChatCountQuery
} from "./CandidateAPI";
import { resolveAddressFromGeocoderResult, useGeocodeQuery } from "../google/GoogleAPI";
import { usePushJobAdToCandidateMutation, useCandidatePdfProfileQuery } from "../job-ads/JobAdAPI";
import { useFormatDate } from "../shared/useFormatDate";
import { CandidateCommentModal } from "./CandidateCommentModal";
import { CandidateChatModal } from "./CandidateChatModal";
import { useAuth } from "../auth/AuthContext";
import { useEffect, useState } from "react";
import { Spinner } from "../shared/Spinner";

const useFetchAddressQuery = mobility => {
  const { data } = useGeocodeQuery(
    mobility ? { location: { lat: mobility.latitude, lng: mobility.longitude } } : null
  );
  const geocoderResult = data && data.length !== 0 ? data[0] : null;
  return geocoderResult ? resolveAddressFromGeocoderResult(geocoderResult) : null;
};


export const NumberRing = ({ text }) => {
  return (
    <span
      tw="flex items-center justify-center ml-2 h-5 min-w-4 w-auto 
              rounded-full my-1 px-1 ring-2 ring-white font-size[0.7rem] font-medium bg-primary-500 text-white"
    >
      {text ? text : ""}
    </span>
  );
};

const DownloadPersonalResumeButton = ({ intelli7ResultsStatus, pictureStatus, videoStatus, candidate, details }) => {
  const { t } = useTranslation();
  const { mutate: personalResume, status } = useCandidateResumeMutation();
  return (
    <Button
      disabled={
        intelli7ResultsStatus !== "success" ||
        pictureStatus !== "success" ||
        videoStatus !== "success"
      }
      onClick={async () => {
        personalResume({
          candidate: candidate,
          extension: details.personalResumeExtension
        })
      }}
      tw="px-2 py-1 sm:(px-4 py-2) text-xs sm:text-sm"
    >
      <DocumentDownloadIcon tw="-ml-1 mr-2 w-3 h-3 sm:(w-5 h-5) text-gray-400" />
      {t("candidates.download-resume")}
    </Button>
  );
};

export const CandidateActionBar = ({ candidate, candidateDetails, candidateFirstMatch = false, jobAdUuidToPush = null, candidateSource = 'AUTHENTICATED', enableChat = false, forceEnableChatButton = false }) => {
  const { mutate: intelli7export, status: intelli7exportStatus } = useIntelli7ExportMutation();
  const { user } = useAuth();
  const candidateId = candidate.uuid;
  const { t, i18n } = useTranslation();
  const formatDate = useFormatDate({ dateStyle: "medium" });
  const {
    status: intelli7ResultsStatus,
    data: intelli7Results
  } = useCandidateQuestionnaireResultsQuery(candidateId);
  const { status: pictureStatus, data: picture } = useCandidatePictureURLQuery(candidateId);
  const { status: videoStatus, data: video } = useCandidateVideoURLQuery(candidateId);
  const { mutate: pushJobAdToCandidate } = usePushJobAdToCandidateMutation();
  const { status: candidateNotesStatus, data: candidatesNotes } = useCandidatesOrganizationNoteQuery(candidate.uuid)
  const { status: candidateUnreadMessagesChatCountStatus, data: candidateUnreadMessagesChatCount } = useCandidateUnreadMessagesChatCountQuery(candidate.uuid);

  const pdfName =
    candidate && candidate.firstname && candidate.lastname
      ? `${candidate.firstname}_${candidate.lastname}_cv.pdf`
      : `jobfirst_${candidateId}_cv.pdf`;
  const address = useFetchAddressQuery(candidateDetails?.mobility);
  const isPushed = candidate => candidate.pushed;
  const onPush = async e => {
    e.preventDefault();
    await pushJobAdToCandidate({
      jobAdUuid: jobAdUuidToPush,
      candidateUuid: candidate.uuid
    });
  };

  const {
    isOpen: isCandidateCommentModalOpen,
    onOpen: onOpenCandidateCommentModal,
    onClose: onCloseCandidateCommentModal
  } = useDisclosure(false);

  const {
    isOpen: isCandidateChatModalOpen,
    onOpen: onOpenCandidateChatModal,
    onClose: onCloseCandidateChatModal
  } = useDisclosure(enableChat);

  const exportCandidateToI7 = async (e) => {
    e.preventDefault();
    // console.log(candidateId);
    await intelli7export(candidateId);
  }

  const displayPersonalResume = !candidateFirstMatch && candidateDetails.personalResumeExtension;

  const [isHoveringDisabledChat, setIsHoveringDisabledChat] = useState(false);
  const [isHoveringDisabledNote, setIsHoveringDisabledNote] = useState(false);

  const { refetch, status: pdfProfileStatus } = useCandidatePdfProfileQuery(jobAdUuidToPush, candidateSource, candidateId);

  return (
    <div tw='w-full flex justify-center'>
      <div tw="flex flex-nowrap gap-1 sm:gap-3 justify-center">
        {!candidateFirstMatch &&
          candidateDetails.personalResumeExtension && (
            <div tw="flex gap-3 sm:(flex-row flex-wrap justify-end)">
              <DownloadPersonalResumeButton
                details={candidateDetails}
                candidate={candidate}
                intelli7ResultsStatus={intelli7ResultsStatus}
                pictureStatus={pictureStatus}
                videoStatus={videoStatus}
              />
            </div>
          )}

        <Button
          disabled={
            intelli7ResultsStatus !== "success" ||
            pictureStatus !== "success" ||
            videoStatus !== "success" ||
            "loading" === pdfProfileStatus
          }
          // onClick={async () => {
          //   const { generatePdf } = await import("@jobook-io/jobook-pdf");
          //   generatePdf(
          //     {
          //       profile: candidate,
          //       details: candidateDetails,
          //       intelli7Results: intelli7Results,
          //       pictureUrl: picture,
          //       recordedVideoUrl: video,
          //       shareIntelli7: true,
          //       shareVideo: true,
          //       lng: i18n.language,
          //       address: address
          //     },
          //     pdfName
          //   );
          // }}
          onClick={() => refetch()}
          tw="px-2 py-1 sm:(px-4 py-2) text-xs sm:text-sm"
        >
          <>
            {
              "loading" === pdfProfileStatus ? (
                <>
                  <Spinner tw="ml-2 mr-2 w-3 h-3 sm:(w-5 h-5) text-gray-500" />
                  {t("candidates.download-profile")}
                </>
              ) : (
                <>
                  <DocumentDownloadIcon tw="-ml-1 mr-2 w-3 h-3 sm:(w-5 h-5) text-gray-400" />
                  {t("candidates.download-profile")}
                </>
              )
            }
          </>
        </Button>

        {!candidateFirstMatch && (
          <>
            {
              candidateSource === 'AUTHENTICATED' ? (
                <Button
                  onClick={onOpenCandidateCommentModal}
                  tw="px-2 py-1 sm:(px-4 py-2) text-xs sm:text-sm"
                >
                  <AnnotationIcon tw="-ml-1 mr-2 w-3 h-3 sm:(w-5 h-5) text-gray-400" />
                  {candidateNotesStatus !== "success" && (
                    <span> {t("candidates.note")}</span>
                  )}
                  {candidateNotesStatus === "success" && candidatesNotes.length > 1 && (
                    <span> {t("candidates.notes")}</span>
                  )}
                  {candidateNotesStatus === "success" && candidatesNotes.length <= 1 && (
                    <span> {t("candidates.note")}</span>
                  )}
                  {candidateNotesStatus === "success" && candidatesNotes.length > 0 && (
                    <NumberRing text={candidatesNotes.length} />
                  )}
                </Button>
              ) : (
                <div
                  tw="relative"
                  onMouseEnter={() => setIsHoveringDisabledNote(true)}
                  onMouseLeave={() => setIsHoveringDisabledNote(false)}>
                  <Button
                    // onClick={onOpenCandidateCommentModal}
                    disabled
                    tw="px-2 py-1 sm:(px-4 py-2) text-xs sm:text-sm"
                  >
                    <AnnotationIcon tw="-ml-1 mr-2 w-3 h-3 sm:(w-5 h-5) text-gray-400" />
                    {/* {candidateNotesStatus !== "success" && (
                    <span> {t("candidates.note")}</span>
                  )}
                  {candidateNotesStatus === "success" && candidatesNotes.length > 1 && ( */}
                    <span> {t("candidates.notes")}</span>
                    {/* )}
                  {candidateNotesStatus === "success" && candidatesNotes.length <= 1 && (
                    <span> {t("candidates.note")}</span>
                  )}
                  {candidateNotesStatus === "success" && candidatesNotes.length > 0 && (
                    <NumberRing text={candidatesNotes.length} />
                  )} */}
                  </Button>
                  {isHoveringDisabledNote && (
                    <div tw="absolute top-full left-1/2 mt-1 bg-gray-600 rounded text-white text-xs p-2  w-[250px] transform -translate-x-1/2">
                      <span tw="block">{t("hover.no-notes")}</span>
                    </div>
                  )}
                </div>
              )
            }
          </>
        )}


        {!isPushed(candidate) && candidateFirstMatch && jobAdUuidToPush && <Button onClick={onPush}>{t("jobs-ads.push")}</Button>}

        {isPushed(candidate) && candidateFirstMatch && (
          <p tw="text-sm text-gray-500 truncate text-center my-3">
            {t("jobs-ads.pushed", { pushDate: formatDate(new Date(candidate.pushDate)) })}
          </p>)
        }

        {(!candidateFirstMatch || forceEnableChatButton) && (
          // <Button as={Link} to={`/chat/${candidateId}`}>
          //   <ChatAlt2Icon tw="-ml-1 mr-2 h-5 w-5 text-gray-400" />
          //   {t("chat.start-chatting")}
          // </Button>
          <>
            {
              candidateSource === 'AUTHENTICATED' ? (
                <Button
                  onClick={onOpenCandidateChatModal}
                  tw="px-2 py-1 sm:(px-4 py-2) text-xs sm:text-sm">
                  <ChatAlt2Icon tw="-ml-1 mr-2 w-3 h-3 sm:(w-5 h-5) text-gray-400" />
                  {t("chat.start-chatting")}
                  {candidateUnreadMessagesChatCountStatus === "success" && candidateUnreadMessagesChatCount > 0 && (
                    <NumberRing text={candidateUnreadMessagesChatCount} />
                  )}
                </Button>
              ) : (
                <div
                  tw="relative"
                  onMouseEnter={() => setIsHoveringDisabledChat(true)}
                  onMouseLeave={() => setIsHoveringDisabledChat(false)}>
                  <Button
                    // onClick={onOpenCandidateChatModal}
                    disabled
                    tw="px-2 py-1 sm:(px-4 py-2) text-xs sm:text-sm"
                  >
                    <ChatAlt2Icon tw="-ml-1 mr-2 w-3 h-3 sm:(w-5 h-5) text-gray-400" />
                    {t("chat.start-chatting")}
                    {/* {candidateUnreadMessagesChatCountStatus === "success" && candidateUnreadMessagesChatCount > 0 && (
                    <NumberRing text={candidateUnreadMessagesChatCount} />
                  )} */}
                  </Button>
                  {isHoveringDisabledChat && (
                    <div tw="absolute top-full left-1/2 mt-1 bg-gray-600 rounded text-white text-xs p-2  w-[250px] transform -translate-x-1/2">
                      <span tw="block">{t("hover.no-chat")}</span>
                    </div>
                  )}
                </div>)
            }
          </>
        )
        }

        {user.hasIntelli7Data && (
          <PrimaryButton onClick={exportCandidateToI7}
            tw="px-2 py-1 sm:(px-4 py-2) text-xs sm:text-sm">
            <UploadIcon tw="-ml-1 mr-2 w-3 h-3 sm:(w-5 h-5) text-gray-400" />
            Export I7
          </PrimaryButton>
        )
        }


        <CandidateCommentModal candidate={candidate} isOpen={isCandidateCommentModalOpen} onClose={onCloseCandidateCommentModal} />
        <CandidateChatModal candidate={candidate} isOpen={isCandidateChatModalOpen} onClose={onCloseCandidateChatModal} />
      </div>
    </div>
  );
};
