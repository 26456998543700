/** @jsxImportSource @emotion/react */
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import "twin.macro";
import tw from "twin.macro";
import { dayLabel } from "../availabilities/availabilities";
import { EmptyState, EmptyStateDescription } from "../shared/EmptyState";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeading,
  TableRow,
  TableRowLabel,
} from "../shared/Table";

/** @type {React.FC<{ isAvailable: boolean }>} */
const CustomTableCell = ({ isAvailable }) => {
  return (
    <TableCell>
      <div
        tw="flex-shrink-0 rounded h-6 w-6 flex items-center justify-center font-medium leading-none text-white mx-auto"
        css={[isAvailable ? tw`bg-green-500` : tw`bg-red-500`]}
      >
        {isAvailable ? <CheckIcon tw="w-5 h-5" /> : <XIcon tw="w-5 h-5" />}
      </div>
    </TableCell>
  );
};

/** @type {React.FC<{ availabilities: import("../job-ads/JobAdAPI").Availabilities }>} */
export const AvailabilityTable = ({ availabilities }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeading />
          <TableHeading tw="px-2 w-1/4 text-center">{t("availabilities.morning")}</TableHeading>
          <TableHeading tw="px-2 w-1/4 text-center">{t("availabilities.afternoon")}</TableHeading>
          <TableHeading tw="px-2 w-1/4 text-center">{t("availabilities.evening")}</TableHeading>
        </TableRow>
      </TableHead>
      <TableBody>
        {["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"].map(
          (day) => (
            <TableRow key={day}>
              <TableRowLabel>{t(dayLabel[day])}</TableRowLabel>
              <CustomTableCell isAvailable={availabilities[day].morning} />
              <CustomTableCell isAvailable={availabilities[day].afternoon} />
              <CustomTableCell isAvailable={availabilities[day].evening} />
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

/** @type {React.FC<{ availabilities: import("../job-ads/JobAdAPI").Availabilities }>} */
export const CandidateAvailabilities = ({ availabilities, empty }) => {
  return (
    <>
      {!availabilities ? (
        <EmptyState tw="bg-gray-100 rounded-lg">
          <EmptyStateDescription tw="mt-0">{empty}</EmptyStateDescription>
        </EmptyState>
      ) : (
        <section tw="px-2 w-full overflow-x-auto rounded-lg overflow-hidden border border-gray-300 bg-white shadow-sm divide-y divide-gray-200">
          <AvailabilityTable availabilities={availabilities} />
        </section>
      )}
    </>
  );
};
