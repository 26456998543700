/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Disclosure, Menu } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import { useAuth, useRole } from "../auth/AuthContext";
import { getDisplayName } from "../candidates/CandidateAPI";
import { useHasChatEnabled, useUnreadMessageCount } from "../chat/ConversationAPI";
import { useJobAdCountByStatus } from "../job-ads/JobAdAPI";
import { useSchoolsQuery } from "../school/SchoolAPI";
import { useLogoOrganizationQuery, useOrganizationTokenCount } from "../settings/OrganizationAPI";
import { Avatar } from "./Avatar";
import { PrimaryButton } from "./Button";
import { Logo } from "./Logo";
import { Transition } from "./Transition";
import i18next from "i18next";
import i18n from "../i18n";

const ChevronForAvatar = () => {
  return (
    <span tw="relative top-3 right-2 flex-shrink-0 h-3 w-3 text-sm rounded-full border-2 overflow-hidden">
      <span tw="absolute flex items-center justify-center font-medium inset-0 leading-none uppercase bg-white text-gray-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </span>
  );
};

export const Layout = ({ children }) => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const hasChatEnabled = useHasChatEnabled();
  const role = useRole();
  const hasSettingsEnabled = role === "ORGANIZATION_ADMIN";
  const tokenCount = useOrganizationTokenCount();
  const { data: countByStatusData } = useJobAdCountByStatus();
  const { data: count } = useUnreadMessageCount();
  const { data: dataLogo, status } = useLogoOrganizationQuery();
  const organizationType = localStorage.getItem("organizationType");
  const { data: schoolSelectionData } = useSchoolsQuery();

  const switchLanguage = () => {
    i18n.changeLanguage(i18next.language === "en" ? "fr" : "en")
  }

  const navigation = [
    user?.role !== "JOBOOK_ADMIN" && countByStatusData?.PUBLISHED === 0 &&
      process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true"
      ? { children: t("job-ads.home"), to: "/welcome" }
      : { children: t("job-ads.home"), to: "/home" },
    user?.role !== "JOBOOK_ADMIN" && process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" && countByStatusData?.PUBLISHED === 0 && {
      children: t("job-ads.my-job-ads"), to: "/job-ads"
    },
    user?.role !== "JOBOOK_ADMIN" && process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" && countByStatusData?.PUBLISHED > 0 && {
      children: t("job-ads.my-job-ads"), to: "/job-ads?adStatus=PUBLISHED"
    },
    "jobfirst" === process.env.REACT_APP_APPLICATION_DOMAIN && ("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan) && {
      children: t("templates.header-title"), to: "/templates"
    },
    user?.role !== "JOBOOK_ADMIN" && schoolSelectionData?.totalElements !== 0 &&
    process.env.REACT_APP_APPLICATION_DOMAIN !== "hupso" && {
      children: t("job-ads.job-ads-school"),
      to: "/job-ads-school"
    },
    user?.role !== "JOBOOK_ADMIN" && schoolSelectionData?.totalElements !== 0 &&
    process.env.REACT_APP_APPLICATION_DOMAIN === "hupso" && {
      children: t("job-ads.job-ads"),
      to: "/job-ads-school"
    },

    user?.role !== "JOBOOK_ADMIN" && user?.organizationPlan === "PREMIUM" && {
      children: t("candidate-search.candidate-search"),
      to: "/candidate-search"
    },
    user?.role !== "JOBOOK_ADMIN" && user?.organizationPlan === "PREMIUM" && {
      children: t("candidate-search.candidate-search-pool"),
      to: "/candidate-search-pool"
    },
    user?.role !== "JOBOOK_ADMIN" && hasChatEnabled && { children: t("chat.chat"), to: "/chat" },
    user?.role !== "JOBOOK_ADMIN" && user?.organizationPlan === "PREMIUM" && { children: t("job-ads.data-title"), to: "/data" },

    user?.role === "JOBOOK_ADMIN" && {
      children: t("organization-admin.organizations"),
      to: "/admin/organizations"
    },

    user?.role === "JOBOOK_ADMIN" && {
      children: t("jobs-admin.jobs"),
      to: "/admin/sectors"
    }
  ].filter(Boolean);

  const navigationSchool = [
    { children: t("job-ads.home"), to: "/home" },
    // process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" &&
    {
      children: t("job-ads.job-ads"), to: "/job-ads"
    },
    {
      children: t("home.students"),
      to: "/students"
    },
    {
      children: t("home.companies"),
      to: "/companies"
    },
    {
      children: t("candidate-search.candidate-search-pool"),
      to: "/candidate-school-search-pool"
    },
    hasChatEnabled && { children: t("chat.chat"), to: "/chat" }
  ].filter(Boolean);

  const languageFlag = () => {
    return (
      <span tw="">
        toto
      </span>

    )
  }

  const userNavigation = [
    { children: t("settings.my-settings"), to: "/my-settings" },
    hasSettingsEnabled && { children: t("settings.organization-settings"), to: "/organization-settings" },
    { as: "button", children: t("shared.sign-out"), onClick: logout }
  ].filter(Boolean);

  const purchaseButton = (
    <div tw="flex items-center">
      <PrimaryButton as={Link} to="/pricing">
        <PlusIcon />
        {t("shared.purchase-job-ads")}
      </PrimaryButton>
    </div>
  );

  const [isDisclosureOpen, setIsDisclosureOpen] = useState(false);

  return (
    <div tw="h-screen flex flex-col min-w-0 flex-1 overflow-hidden bg-gray-50">
      <Disclosure as="nav" tw="bg-white border-b border-gray-200">
        {({ open, close }) => {
          if (isDisclosureOpen !== open) {
            setIsDisclosureOpen(open);
          }
          return (
            <>
              <div tw="px-4 sm:px-6">
                <div tw="flex justify-between h-16">
                  <div tw="flex">
                    {countByStatusData?.PUBLISHED !== 0 || organizationType === "SCHOOL" ? (
                      <Link to="/home" tw="flex-shrink-0 flex items-center">
                        {status === "success" &&
                          organizationType === "SCHOOL" &&
                          process.env.REACT_APP_APPLICATION_DOMAIN === "jobfirst" &&
                          dataLogo !== null ? (
                          <img
                            tw="h-8 w-auto"
                            alt="logo organization"
                            src={URL.createObjectURL(dataLogo)}
                          />
                        ) : (
                          <>
                            {"jobfirst" === process.env.REACT_APP_APPLICATION_DOMAIN && ("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan) ? (
                              <img
                                tw="w-auto h-8 lg:h-8"
                                src={`${process.env.REACT_APP_DEV_PUBLIC_MEDIA_URL}/organization/logo/original/jobfirstx${user?.organizationPlan.toLowerCase()}.jpeg`}
                                alt="logo organization"
                                onError={(event) => (event.target.style.display = "none")}
                              />) : (<img
                                tw="w-auto h-8 lg:h-8"
                                src={`${process.env.REACT_APP_DEV_PUBLIC_MEDIA_URL}/organization/logo/original/${process.env.REACT_APP_APPLICATION_DOMAIN}.jpeg`}
                                alt="logo organization"
                                onError={(event) => (event.target.style.display = "none")}
                              />
                            )
                            }
                          </>
                        )}
                      </Link>
                    ) : (
                      <Link to="/welcome" tw="flex-shrink-0 flex items-center">
                        {status === "success" &&
                          organizationType === "SCHOOL" &&
                          process.env.REACT_APP_APPLICATION_DOMAIN === "jobfirst" &&
                          dataLogo !== null ? (
                          <img
                            tw="h-8 w-auto"
                            alt="logo organization"
                            src={URL.createObjectURL(dataLogo)}
                          />
                        ) : (
                          <>
                            {"jobfirst" === process.env.REACT_APP_APPLICATION_DOMAIN && ("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan) ? (
                              <img
                                tw="w-auto h-8 lg:h-8"
                                src={`${process.env.REACT_APP_DEV_PUBLIC_MEDIA_URL}/organization/logo/original/jobfirstx${user?.organizationPlan.toLowerCase()}.jpeg`}
                                alt="logo organization"
                                onError={(event) => (event.target.style.display = "none")}
                              />) : (<img
                                tw="w-auto h-8 lg:h-8"
                                src={`${process.env.REACT_APP_DEV_PUBLIC_MEDIA_URL}/organization/logo/original/${process.env.REACT_APP_APPLICATION_DOMAIN}.jpeg`}
                                alt="logo organization"
                                onError={(event) => (event.target.style.display = "none")}
                              />
                            )
                            }
                          </>
                        )}
                      </Link>
                    )}
                    <div tw="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {organizationType !== "SCHOOL"
                        ? navigation.map((navigationItem, index) => (
                          <NavLink
                            key={index}
                            tw="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            css={{
                              "&": tw`border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700`,
                              "&.active": tw`border-primary-500 text-gray-900 hover:border-primary-500 hover:text-gray-900`
                            }}
                            to={navigationItem.to}
                          >
                            {navigationItem.children}
                            {count && navigationItem.children === "Chat" ? (
                              <span tw="flex items-center justify-center ml-2 h-4 w-4 rounded-full ring-2 ring-white font-size[0.5rem] font-medium bg-primary-500 text-white">
                                {count}
                              </span>
                            ) : null}
                          </NavLink>
                        ))
                        : navigationSchool.map((navigationItem, index) => (
                          <NavLink
                            key={index}
                            tw="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            css={{
                              "&": tw`border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700`,
                              "&.active": tw`border-primary-500 text-gray-900 hover:border-primary-500 hover:text-gray-900`
                            }}
                            to={navigationItem.to}
                          >
                            {navigationItem.children}
                            {count && navigationItem.children === "Chat" ? (
                              <span tw="flex items-center justify-center ml-2 h-4 w-4 rounded-full ring-2 ring-white font-size[0.5rem] font-medium bg-primary-500 text-white">
                                {count}
                              </span>
                            ) : null}
                          </NavLink>
                        ))}
                    </div>
                  </div>

                  <div tw="hidden sm:ml-6 sm:flex sm:items-center space-x-4">
                    {/* These free elements should be visible only if classical jobads is available */}
                    {schoolSelectionData?.totalElements !== 0 &&
                      user?.organizationPlan !== "PREMIUM" &&
                      process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" && (
                        <p tw="text-sm font-medium text-gray-700">
                          {t("home.free-publish-catchphrase")}
                        </p>
                      )}
                    {user?.organizationPlan === "PREMIUM" ||
                      (
                        organizationType !== "SCHOOL" &&
                        process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" &&
                        purchaseButton
                      )}
                    {user?.organizationPlan !== "PREMIUM" &&
                      organizationType !== "SCHOOL" &&
                      process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" && (
                        <span tw="text-sm text-gray-500 truncate">
                          {t("shared.creditsNumber", { count: tokenCount })}
                        </span>
                      )}
                    {/* Profile dropdown */}
                    <Menu as="div" tw="relative">
                      <div>
                        <Menu.Button tw="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                          <span tw="sr-only">{t("home.open-user-menu")}</span>
                          <Avatar tw="h-8 w-8 rounded-full" user={user} />
                          <ChevronForAvatar />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter={tw`transition ease-out duration-200`}
                        enterFrom={tw`transform opacity-0 scale-95`}
                        enterTo={tw`transform opacity-100 scale-100`}
                        leave={tw`transition ease-in duration-75`}
                        leaveFrom={tw`transform opacity-100 scale-100`}
                        leaveTo={tw`transform opacity-0 scale-95`}
                      >
                        <Menu.Items tw="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map(({ as: Component = Link, ...props }, index) => (
                            <Menu.Item key={index}>
                              {({ active }) => (
                                <Component
                                  tw="block w-full text-left px-4 py-2 text-sm text-gray-700 focus:outline-none"
                                  css={active && tw`bg-gray-100`}
                                  {...props}
                                />
                              )}
                            </Menu.Item>
                          ))}
                          {/* <Menu.Item>
                          {({ active }) => (
                            <Link
                              tw="block w-full text-left px-4 py-2 text-sm text-gray-700 focus:outline-none"
                              css={active && tw`bg-gray-100`}
                              onClick={switchLanguage}
                            >
                              {
                                i18n.language === 'en' ? (
                                  <img src="/homeIcons/france-flag-icon.svg" tw="max-h-5" alt="" data-ll-status="loaded" />
                                ) : (
                                  <img src="/homeIcons/united-kingdom-flag-icon.svg" tw="max-h-5" alt="" data-ll-status="loaded" />
                                )
                              }
                            </Link>
                          )}
                        </Menu.Item> */}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <div
                      tw="h-8 w-8 pt-2 cursor-pointer"
                      onClick={switchLanguage}
                    >
                      {
                        i18n.language === 'en' ? (
                          <img src="/homeIcons/france-flag-icon.svg" tw="max-h-5" alt="" data-ll-status="loaded" />
                        ) : (
                          <img src="/homeIcons/united-kingdom-flag-icon.svg" tw="max-h-5" alt="" data-ll-status="loaded" />
                        )
                      }
                    </div>
                  </div>
                  <div tw="flex items-center sm:hidden">
                    {user?.organizationPlan !== "PREMIUM" &&
                      organizationType !== "SCHOOL" &&
                      process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" && (
                        <span tw="text-sm text-gray-500 truncate">
                          {t("shared.creditsNumber", { count: tokenCount })}
                        </span>
                      )}
                  </div>
                  <div tw="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button tw="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                      <span tw="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon tw="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon tw="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
              <Disclosure.Panel tw="sm:hidden">
                {/* {process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" &&
                  <div tw="p-4">
                    <div tw="flex items-center gap-1">
                      <span>{purchaseButton}</span>
                    </div>
                  </div>
                } */}
                <div tw="py-4 space-y-1">
                {process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" &&
                  <NavLink
                    tw="flex pl-3 pr-4 py-4 text-base font-bold self-center "
                    css={{
                      "&": tw`bg-primary-600 border-transparent text-white hover:bg-primary-300 hover:text-white`,
                      // "&.active": tw`bg-primary-50 border-primary-500 text-white hover:bg-primary-50 hover:border-primary-500 hover:text-white`
                    }}
                    to={"/pricing"}
                    onClick={close}
                  >
                    <PlusIcon tw="h-5 w-5 mr-2"/>
                    {t("shared.purchase-job-ads")}
                  </NavLink>
                }
                  {organizationType !== "SCHOOL"
                    ? navigation.map(({ as: Component = NavLink, ...props }, index) => (
                      <NavLink
                        key={index}
                        tw="block pl-3 pr-4 py-4 border-l-4 text-base font-bold"
                        css={{
                          "&": tw`border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800`,
                          "&.active": tw`bg-primary-50 border-primary-500 text-primary-700 hover:bg-primary-50 hover:border-primary-500 hover:text-primary-700`
                        }}
                        {...props}
                        onClick={close}
                      />
                    ))
                    : navigationSchool.map(({ as: Component = NavLink, ...props }, index) => (
                      <NavLink
                        key={index}
                        tw="block pl-3 pr-4 py-4 border-l-4 text-base font-medium"
                        css={{
                          "&": tw`border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800`,
                          "&.active": tw`bg-primary-50 border-primary-500 text-primary-700 hover:bg-primary-50 hover:border-primary-500 hover:text-primary-700`
                        }}
                        {...props}
                        onClick={close}
                      />
                    ))}
                </div>
                <hr />
                <div tw="pb-3 ">
                  <div tw="mt-3 space-y-1">
                    {userNavigation.map(({ as: Component = Link, onClick = null, ...props }, index) => (
                      <Component
                        key={index}
                        tw="block w-full text-left px-4 py-4 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none"
                        {...props}
                        onClick={onClick ? onClick : close}
                      />
                    ))}
                  </div>
                  <hr />
                  <div tw="flex items-center mt-3 px-4 pt-2 pb-4">
                    <Avatar tw="h-10 w-10 rounded-full" user={user} />
                    <div tw="ml-3">
                      <div tw="text-base font-medium text-gray-800">{getDisplayName(user)}</div>
                      <div tw="text-sm font-medium text-gray-500">{user ? user.email : null}</div>
                    </div>
                  </div>
                  <hr />
                  <button
                    tw="flex w-full text-left px-4 pt-4 pb-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none"
                    onClick={switchLanguage}
                  >
                    {t("shared.switch-language")}
                    {
                      i18n.language === 'en' ? (
                        <img src="/homeIcons/france-flag-icon.svg" tw="max-h-5 pl-4" alt="" data-ll-status="loaded" />
                      ) : (
                        <img src="/homeIcons/united-kingdom-flag-icon.svg" tw="max-h-5 pl-4" alt="" data-ll-status="loaded" />
                      )
                    }
                  </button>
                </div>
              </Disclosure.Panel>
            </>
          )
        }}
      </Disclosure>

      <div tw="flex-1 flex overflow-hidden"
        css={[
          isDisclosureOpen && tw`opacity-0` // Appliquer un ombrage si isDisclosureOpen est vrai
        ]}
      >{children}</div>
    </div>
  );
};
