/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import qs from "qs";
import { MailIcon, PhoneIcon, XIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import "twin.macro";
import tw from "twin.macro";
import { diplomaLabel } from "../diplomas/diplomas";
import { Avatar } from "../shared/Avatar";
import { Container } from "../shared/Container";
import { useState, useEffect } from "react";
import { useSearchParams } from "../shared/useSearchParams";
import { Button, PrimaryButton, GrayFooterMobileButton, PrimaryFooterMobileButton } from "../shared/Button";
import {
  DescriptionList,
  DescriptionTerm,
  DescriptionTermLabel,
  DescriptionTermValue
} from "../shared/DescriptionList";
import { Empty } from "../shared/Empty";
import { AvatarSkeleton } from "../shared/Skeleton";
import { useFormatDate } from "../shared/useFormatDate";
import { getDisplayName, useCandidateVideoURLQuery } from "./CandidateAPI";
import { useJobAdCandidatesByStatusQuery } from "../job-ads/JobAdAPI";
import { Link, useParams } from "react-router-dom";
import { CandidateAvailabilities } from "./CandidateAvailabilities";
import { CandidateDriverInformation } from "./CandidateDriverInformation";
import { CandidateExperienceYears } from "./CandidateExperienceYears";
import { CandidateLocation } from "./CandidateLocation";
import { CandidateTitle } from "./CandidateTitle";
import { ExperienceList } from "./ExperienceList";
import { LanguageList } from "./LanguageList";
import { QuestionnaireSection } from "./QuestionnaireSection";
import { SkillList } from "./SkillList";
import { Video } from "../video/Video";
import { StarRating } from "../shared/StarRating";


const handleProfileOrder = (jobAd) => {
  console.log("handleProfileOrder");
  const categories = [
    { key: 'matchingHardSkillsPosition', name: 'HARD_SKILLS' },
    { key: 'matchingSoftSkillsPosition', name: 'SOFT_SKILLS' },
    { key: 'matchingAccreditationsDiplomaPosition', name: 'ACCREDITATIONS' },
    { key: 'matchingAvailabilitiesPosition', name: 'AVAILABILITIES' },
    // { key: 'matchingLicencesPosition', name: 'LICENCES' },
    { key: 'matchingExperiencesPosition', name: 'EXPERIENCES' },
  ].map(category => ({
    ...category,
    value: jobAd[category.key],
  }));

  const sortedWithNullsLast = categories
    .filter(category => category.value === null || category.value === 0)
    .sort((a, b) => {
      const order = ['EXPERIENCES', 'ACCREDITATIONS', 'HARD_SKILLS', 'SOFT_SKILLS', 'AVAILABILITIES']//, 'LICENCES'];
      return order.indexOf(a.name) - order.indexOf(b.name);
    })
    .map(category => category.name);

  const sortedWithValues = categories
    .filter(category => category.value !== null && category.value > 0)
    .sort((a, b) => a.value - b.value)
    .map(category => category.name);

  console.log(jobAd);
  console.log([...sortedWithValues, ...sortedWithNullsLast]);
  return [...sortedWithValues, ...sortedWithNullsLast];
}


export const CandidateDetailsSkeleton = () => {
  return (
    <article tw="w-full">
      <header>
        <Container tw="py-4">
          <div tw="sm:flex sm:items-end sm:space-x-5">
            <div tw="flex">
              <AvatarSkeleton tw="h-24 w-24 ring-4 ring-white sm:(h-32 w-32)" />
            </div>
          </div>
        </Container>
      </header>
    </article>
  );
};

const Section = styled("section")(tw`space-y-1`);
const SectionTitle = styled("h2")(tw`text-sm font-medium text-gray-500`);

/** @type {React.FC<{ candidate: import("./CandidateAPI").Candidate, candidateDetails: import("./CandidateAPI").CandidateDetails }>} */
export const CandidateDetails = ({
  candidate,
  candidateDetails,
  meta,
  actions,
  highlightedLicences,
  highlightRequiresVehicle,
  highlightedSkills,
  highlightedActivities,
  highlightedAccreditations,
  highlightedQualities,
  highlightedLanguages,
  candidateFirstMatch,
  backCandidateDetailsButton,
  jobAdLocation,
  jobAd = null
}) => {
  const { jobAdId, candidateId } = useParams();
  const { t } = useTranslation();
  const formatDate = useFormatDate({ dateStyle: "medium" });
  const { data: video } = useCandidateVideoURLQuery(candidateDetails.candidateUuid);
  const [isVideoRecorded] = useState(() => !!video);
  const displayNameForAvatar = { firstname: candidate.firstname, lastname: candidate.lastname };
  const { page, size, pageCandidate, status, adStatus, adTag, type, owner, source, chat, position, city } = useSearchParams();

  const [pageCandidateToSearch, setPageCandidateToSearch] = useState(pageCandidate ? pageCandidate - 1 : 0);
  const { status: jobAdCandidateListStatus, data: jobAdCandidateList, refetch: refetchJobAdList } = useJobAdCandidatesByStatusQuery(jobAdId, {
    page: pageCandidateToSearch,
    size: size ? size : 10,
    status
  });

  const [savedLastPosition, setSavedLastPosition] = useState(position);
  const [positionForList, setPositionForList] = useState(position);
  const [previousCandidateDetailsButton, setPreviousCandidateDetailsButton] = useState(null);
  const [nextCandidateDetailsButton, setNextCandidateDetailsButton] = useState(null);

  const [profileOrder, setProfileOrder] = useState(jobAd ? handleProfileOrder(jobAd) : ["EXPERIENCES", "ACCREDIATIONS", "HARD_SKILLS", "SOFT_SKILLS", "AVAILABILITIES"]);

  // if (jobAd) {
  //   handleProfileOrder(jobAd, setProfileOrder);
  // }

  useEffect(() => {
    if (jobAdCandidateList) {
      const candidateList = jobAdCandidateList.content;

      // Check if position is FIRST or LAST
      // pageCandidateToSearch + 1 for FIRST and LAST because the data is already updated
      if ("FIRST" === positionForList) {
        const previousCandidate = candidateList[candidateList.length - 1];
        setPreviousCandidateDetailsButton({
          pathname: `/job-ads/${jobAdId}/candidates/${previousCandidate.uuid}`,
          search: qs.stringify(
            { page, pageCandidate: pageCandidateToSearch + 1, status, adStatus, adTag, type, owner, source: previousCandidate.source, chat: previousCandidate.allMessagesChatCounter > 0 ? "ENABLED" : "DISABLED", city },
            { addQueryPrefix: true }
          )
        });
        setPositionForList(null);
      } else if ("LAST" === positionForList) {
        const nextCandidate = candidateList[0];
        setNextCandidateDetailsButton({
          pathname: `/job-ads/${jobAdId}/candidates/${nextCandidate.uuid}`,
          search: qs.stringify(
            { page, pageCandidate: pageCandidateToSearch + 1, status, adStatus, adTag, type, owner, source: nextCandidate.source, chat: nextCandidate.allMessagesChatCounter > 0 ? "ENABLED" : "DISABLED", city },
            { addQueryPrefix: true }
          )
        });
        setPositionForList(null);
      } else {

        setNextCandidateDetailsButton(null);
        setPreviousCandidateDetailsButton(null);

        const currentCandidateIndex = candidateList.findIndex((el) => el.uuid === candidate.uuid);
        if (currentCandidateIndex === -1) {
          if ("LAST" === savedLastPosition) {
            setPageCandidateToSearch(pageCandidateToSearch - 1);
            refetchJobAdList();
          }
          if ("FIRST" === savedLastPosition) {
            setPageCandidateToSearch(pageCandidateToSearch + 1);
            refetchJobAdList();
          }
        }
        if (currentCandidateIndex > 0) {
          const previousCandidate = candidateList[currentCandidateIndex - 1];
          setPreviousCandidateDetailsButton({
            pathname: `/job-ads/${jobAdId}/candidates/${previousCandidate.uuid}`,
            search: qs.stringify(
              { page, pageCandidate: pageCandidateToSearch + 1, status, adStatus, adTag, type, owner, source: previousCandidate.source, chat: previousCandidate.allMessagesChatCounter > 0 ? "ENABLED" : "DISABLED", city },
              { addQueryPrefix: true }
            )
          });
        }
        if (currentCandidateIndex >= 0 && currentCandidateIndex < (candidateList.length - 1)) {
          const nextCandidate = candidateList[currentCandidateIndex + 1];
          setNextCandidateDetailsButton({
            pathname: `/job-ads/${jobAdId}/candidates/${nextCandidate.uuid}`,
            search: qs.stringify(
              { page, pageCandidate: pageCandidateToSearch + 1, status, adStatus, adTag, type, owner, source: nextCandidate.source, chat: nextCandidate.allMessagesChatCounter > 0 ? "ENABLED" : "DISABLED", city },
              { addQueryPrefix: true }
            )
          });
        }
        // Check to reload list with previous page.
        if (currentCandidateIndex == 0) {
          if (jobAdCandidateList.totalPages && jobAdCandidateList.pageable && jobAdCandidateList.pageable.pageNumber > 0) {
            setPositionForList("FIRST");
            setSavedLastPosition("FIRST");
            setPageCandidateToSearch(pageCandidateToSearch - 1);
            refetchJobAdList();
          }
        }
        // Check to reload list with next page.
        if (currentCandidateIndex == (candidateList.length - 1)) {
          if (jobAdCandidateList.totalPages && jobAdCandidateList.pageable && jobAdCandidateList.pageable.pageNumber < jobAdCandidateList.totalPages - 1) {
            setPageCandidateToSearch(pageCandidateToSearch + 1);
            setPositionForList("LAST");
            setSavedLastPosition("LAST");
            refetchJobAdList();
          }
        }
      }
    }

  }, [candidate, jobAdCandidateList]);

  return (
    <article tw='bg-gray-50'>
      {/* Profile header */}
      <header tw="bg-white border-b border-gray-100 lg:sticky top-0 z-50 rounded-b-2xl shadow-lg">
        <Container tw="py-2">
          {/* Candidate title */}
          <div tw="flex items-end sm:space-x-5 relative">
            <div tw="flex justify-center w-full">
              <div tw="flex items-center mt-4 sm:mt-2">
                {/* Candidate avatar */}
                <Avatar
                  tw="text-4xl h-24 w-24 ring-4 ring-white md:(h-32 w-32)"
                  user={candidateFirstMatch ? displayNameForAvatar : candidate}
                />
                <div tw="text-left flex flex-col ml-4">

                  <h1 tw="text-base sm:text-2xl font-bold text-gray-900 break-words">
                    {getDisplayName(candidate)}
                  </h1>
                  <p tw="text-sm font-medium text-gray-500 break-words">
                    <CandidateTitle candidateDetails={candidateDetails} />
                    <p tw="text-sm font-medium text-gray-500 truncate">
                      <CandidateExperienceYears candidateDetails={candidateDetails} />
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div tw="absolute top-0 right-0 flex">
              <nav tw="flex items-start sm:px-6" aria-label="Breadcrumb">
                <Link
                  to={backCandidateDetailsButton}
                  tw="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <XIcon tw="h-8 w-8 text-gray-400" aria-hidden="true" />
                </Link>
              </nav>
            </div>
          </div>
          <div tw="flex flex-col gap-4 md:(flex-row items-center) mb-4">
            <div tw="flex-1 flex-shrink-0 md:w-2/5">
              {meta && <div tw="flex flex-wrap mt-2 justify-center w-full">{meta}</div>}
            </div>

          </div>
          {/* Candidate actions */}
          <div tw="flex flex-col gap-3 sm:(flex-col items-end)">{actions}</div>
        </Container>
      </header>
      <Container tw="bg-white space-y-8 pb-12 pt-6 mt-3 border-t border-gray-100 rounded-t-2xl">
        {/* Description list */}
        <Section>
          <DescriptionList>
            <div tw="flex space-x-4 justify-between items-center">
              <DescriptionTerm>
                <DescriptionTermLabel>{t("candidates.phone")}</DescriptionTermLabel>
                {candidateFirstMatch && <DescriptionTermValue tw="filter blur-sm">0612345678</DescriptionTermValue>}
                {!candidateFirstMatch && <DescriptionTermValue>{candidate.phone?.number || <Empty />}</DescriptionTermValue>}
              </DescriptionTerm>
              {candidate.phone?.number && (
                <a
                  href={`tel:${candidate.phone?.number}`}
                  target="_blank"
                  rel="noreferrer"
                  tw="inline-flex items-center shadow-sm px-2.5 py-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                >
                  <PhoneIcon tw="text-gray-400 h-4 w-4" />
                  <span tw="sr-only">{t("candidates.call")}</span>
                </a>
              )}
            </div>
            <div tw="flex space-x-4 justify-between items-center">
              <DescriptionTerm>
                <DescriptionTermLabel>{t("candidates.email")}</DescriptionTermLabel>
                {candidateFirstMatch && <DescriptionTermValue tw="filter blur-sm">noavailable@mail.com</DescriptionTermValue>}
                {!candidateFirstMatch && <DescriptionTermValue>{candidate.email || <Empty />}</DescriptionTermValue>}
              </DescriptionTerm>
              {candidate.email && (
                <a
                  href={`mailto:${candidate.email}`}
                  target="_blank"
                  rel="noreferrer"
                  tw="inline-flex items-center shadow-sm px-2.5 py-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                >
                  <MailIcon tw="text-gray-400 h-4 w-4" />
                  <span tw="sr-only">{t("candidates.send-email")}</span>
                </a>
              )}
            </div>
            <DescriptionTerm>
              <DescriptionTermLabel>{t("candidates.title")}</DescriptionTermLabel>
              <DescriptionTermValue>
                <CandidateTitle candidateDetails={candidateDetails} />
              </DescriptionTermValue>
            </DescriptionTerm>
            <DescriptionTerm>
              <DescriptionTermLabel>{t("candidates.location")}</DescriptionTermLabel>
              <DescriptionTermValue>
                <CandidateLocation candidateDetails={candidateDetails} jobAdLocation={jobAdLocation} />
              </DescriptionTermValue>
            </DescriptionTerm>
            <DescriptionTerm>
              <DescriptionTermLabel>{t("candidates.driver-information")}</DescriptionTermLabel>
              <DescriptionTermValue>
                <CandidateDriverInformation
                  driverLicences={candidateDetails.driverLicences}
                  highlightedLicences={highlightedLicences}
                  highlightRequiresVehicle={highlightRequiresVehicle}
                />
              </DescriptionTermValue>
            </DescriptionTerm>
            <DescriptionTerm>
              <DescriptionTermLabel>{t("candidates.diploma")}</DescriptionTermLabel>
              <DescriptionTermValue>
                {candidateDetails.lastDiploma ? (
                  t(diplomaLabel[candidateDetails.lastDiploma])
                ) : (
                  <Empty />
                )}
              </DescriptionTermValue>
            </DescriptionTerm>
            <DescriptionTerm>
              <DescriptionTermLabel>{t("candidates.birthday")}</DescriptionTermLabel>
              <DescriptionTermValue>
                {candidate.birthdate ? formatDate(new Date(candidate.birthdate)) : <Empty />}
              </DescriptionTermValue>
            </DescriptionTerm>
            {
              candidate.handicapSituation &&
              <DescriptionTerm>
                <DescriptionTermLabel>{t("candidates.handicap-situation")}</DescriptionTermLabel>
                <DescriptionTermValue>
                  {t("candidates.Yes")}
                </DescriptionTermValue>
              </DescriptionTerm>
            }
          </DescriptionList>
        </Section>

        {
          profileOrder.map((el => (
            <>
              {
                el === 'ACCREDITATIONS' && candidateDetails.candidateSkills?.accreditations && (
                  <>
                    {/* Accreditation list */}
                    {(!jobAd || (jobAd && candidateDetails.candidateSkills?.accreditations.length > 0)) && (
                      <Section>
                        <SectionTitle>{t("candidates.accreditations")}</SectionTitle>
                        <SkillList
                          skills={candidateDetails.candidateSkills?.accreditations}
                          highlighted={highlightedAccreditations}
                          empty={t("candidates.empty-accreditations")}
                        />
                      </Section>
                    )}
                  </>
                )
              }
              {
                el === 'EXPERIENCES' && candidateDetails.experiences.length > 0 && (
                  <>
                    {/* Experience list */}
                    <Section>
                      <SectionTitle>{t("candidates.experiences")}</SectionTitle>
                      <ExperienceList
                        experiences={[...candidateDetails.experiences].reverse()}
                        empty={t("candidates.empty-experiences")}
                      />
                    </Section>
                  </>
                )
              }
              {
                el === 'HARD_SKILLS' && candidateDetails.candidateSkills?.skills && (
                  <>
                    {/* Skill list */}
                    {(!jobAd || (jobAd && candidateDetails.candidateSkills?.skills.length > 0)) && (
                      <Section>
                        <SectionTitle>{t("candidates.skills")}</SectionTitle>
                        <SkillList
                          skills={candidateDetails.candidateSkills?.skills}
                          highlighted={highlightedSkills}
                          empty={t("candidates.empty-skills")}
                        />
                      </Section>
                    )}
                  </>
                )
              }
              {
                el === 'HARD_SKILLS' && candidateDetails.candidateSkills?.activities && (
                  <>
                    {/* Activity list */}
                    {(!jobAd || (jobAd && candidateDetails.candidateSkills?.activities.length > 0)) && (
                      <Section>
                        <SectionTitle>{t("candidates.activities")}</SectionTitle>
                        <SkillList
                          skills={candidateDetails.candidateSkills?.activities}
                          highlighted={highlightedActivities}
                          empty={t("candidates.empty-activities")}
                        />
                      </Section>
                    )}
                  </>
                )
              }
              {
                el === 'SOFT_SKILLS' && (
                  <>
                    {/* Questionnaire result list */}
                    <QuestionnaireSection
                      candidateId={candidate.uuid}
                      highlighted={highlightedQualities}
                      empty={t("candidates.empty-qualities")}
                      source={source || "AUTHENTICATED"}
                    />
                  </>
                )
              }
              {
                el === 'AVAILABILITIES' && (
                  <>
                    {/* Candidate availabilities */}
                    {(!jobAd || (jobAd && candidateDetails.availabilities)) && (
                      <Section>
                        <SectionTitle>{t("candidates.availabilities")}</SectionTitle>
                        <CandidateAvailabilities
                          availabilities={candidateDetails.availabilities}
                          empty={t("candidates.empty-availabilities")}
                        />
                      </Section>
                    )}
                  </>
                )
              }
            </>
          )))
        }

        {/* Language list */}
        {candidateDetails.spokenLanguages.length > 0 && (
          <Section>
            <SectionTitle>{t("candidates.languages")}</SectionTitle>
            <LanguageList
              languages={candidateDetails.spokenLanguages}
              highlighted={highlightedLanguages}
              empty={t("candidates.empty-languages")}
            />
          </Section>
        )}


        {isVideoRecorded && (
          <Section>
            <SectionTitle>{t("candidates.video")}</SectionTitle>
            <div tw="relative">
              <Video
                width="100%"
                controls
                src={video}
                style={{ background: "#e5e7eb" }}
                playsInline
              />
            </div>
          </Section>
        )}
        {/* This is a hack to keep old display / This is a hack to keep old display / This is a hack to keep old display */}
        <Section tw="opacity-0">
          <ul tw="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <li
              tw="rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-start justify-between space-x-2">

              <p tw="text-sm font-medium text-gray-900 flex-1" css={{ "&:first-letter": tw`capitalize` }}>
                This is a hack to keep old display / This is a hack to keep old display / This is a hack to keep old display
              </p>
              <StarRating rating={5} />
            </li>
          </ul>
        </Section>
      </Container>

      <div tw="fixed bottom-0 right-0 py-3 px-8 sm:px-6 bg-white border-t border-gray-200 max-w-3xl w-full">
        {jobAdId && (previousCandidateDetailsButton || nextCandidateDetailsButton) && (
          <div tw="flex space-x-3 items-center justify-between w-full px-20">
            {previousCandidateDetailsButton ? (
              <GrayFooterMobileButton aria-label="Breadcrumb">
                <Link
                  to={previousCandidateDetailsButton}
                  tw="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronLeftIcon tw="h-4 w-4 sm:(h-8 w-8) text-gray-400" aria-hidden="true" />
                </Link>
              </GrayFooterMobileButton>
            ) : (
              <GrayFooterMobileButton aria-label="Breadcrumb" disabled tw="opacity-0">
                <Link
                  to={previousCandidateDetailsButton}
                  tw="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronLeftIcon tw="h-4 w-4 sm:(h-8 w-8) text-gray-400" aria-hidden="true" />
                </Link>
              </GrayFooterMobileButton>
            )}

            {nextCandidateDetailsButton ? (
              <GrayFooterMobileButton aria-label="Breadcrumb">
                <Link
                  to={nextCandidateDetailsButton}
                  tw="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronRightIcon tw="h-4 w-4 sm:(h-8 w-8) text-gray-400" aria-hidden="true" />
                </Link>
              </GrayFooterMobileButton>
            ) : (
              <GrayFooterMobileButton aria-label="Breadcrumb" disabled tw="opacity-0">
                <Link
                  to={nextCandidateDetailsButton}
                  tw="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronRightIcon tw="h-4 w-4 sm:(h-8 w-8) text-gray-400" aria-hidden="true" />
                </Link>
              </GrayFooterMobileButton>
            )}
          </div>
        )}
      </div>
    </article>
  );
};
