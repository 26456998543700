import { ErrorBoundary } from "@sentry/react";
import { Suspense, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { I18nextProvider, useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyles } from "twin.macro";
import { AuthProvider } from "./auth/AuthContext";
import i18n from "./i18n";
import { Routes } from "./Routes";
import { ErrorFallback } from "./shared/ErrorFallback";
import './custom.css';
// import "react-datepicker/dist/react-datepicker.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const SEO = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{process.env.REACT_APP_APPLICATION_NAME}</title>
      <meta
        name="description"
        content={t("home.title", {
          applicationName: process.env.REACT_APP_APPLICATION_NAME,
        })}
      />
      <meta name="apple-mobile-web-app-title" content={process.env.REACT_APP_APPLICATION_NAME} />
      <meta name="application-name" content={process.env.REACT_APP_APPLICATION_NAME} />
      <meta name="theme-color" content="#ffffff" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/${process.env.REACT_APP_FAVICONS}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/${process.env.REACT_APP_FAVICONS}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/${process.env.REACT_APP_FAVICONS}/favicon-16x16.png`}
      />
      <link rel="manifest" href={`/${process.env.REACT_APP_FAVICONS}/manifest.json`} />
      <link rel="mask-icon" href="%PUBLIC_URL%/safari-pinned-tab.svg?v=E6mrKkXp5e" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#2d89ef" />
      <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7286466.js"></script> 
    </Helmet>
  );
};

const App = () => {


  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE) {
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);

      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE}`;
      script.async = true;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <Suspense fallback={null}>
        <QueryClientProvider client={queryClient}>
          <GlobalStyles />
          <I18nextProvider i18n={i18n}>
            <HelmetProvider>
              <Helmet title={process.env.REACT_APP_APPLICATION_NAME} />
              <AuthProvider>
                <Suspense fallback={null}>
                  <SEO />
                </Suspense>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </AuthProvider>
            </HelmetProvider>
          </I18nextProvider>
        </QueryClientProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
