/** @jsxImportSource @emotion/react */
import { Dialog } from "@headlessui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import { XIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import "twin.macro";
import tw from "twin.macro";
import { Transition } from "../shared/Transition";
import { useAuth } from "../auth/AuthContext";
import { css } from '@emotion/react';


import { CometChatContextProvider, CometChatMessages, theme } from "@jobook-io/jobook-chat";
import {
  useAuthenticateCometChatMutation,
  useChatAccessToken,
  useInitiateChatMutation,
  useMessageSentMutation,
} from "../chat/ConversationAPI";
import { useCometChatLanguage } from "../chat/useCometChatLanguage";


export const CandidateChatModal = ({ candidate, isOpen, onClose }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const formProps = useForm({
    defaultValues: { content: "" },
  });

  const lang = useCometChatLanguage();
  const chatWithUser = candidate.uuid;
  const { mutate: authenticateCometChat, status: authenticateCometChatStatus } =
    useAuthenticateCometChatMutation();
  const { mutate: initiateChat, status: initiateChatStatus } = useInitiateChatMutation();
  const { mutate: messageSent } = useMessageSentMutation();
  const chatAccessToken = useChatAccessToken();

  useEffect(() => {
    // Initiate a chat with the current user, this will create an account on CometChat for this user and the autenticated user
    initiateChat({ chatWithUser });
  }, [initiateChat, chatWithUser]);

  useEffect(() => {
    // If user has a chat access token, authenticate it to CometChat
    if (chatAccessToken) {
      authenticateCometChat({ chatAccessToken });
    }
  }, [authenticateCometChat, chatAccessToken]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        tw="fixed z-[110] xl:z-[90] inset-0 overflow-y-auto"
        open={isOpen}
        onClose={onClose}
      >
        <div tw="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center lg:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter={tw`ease-out duration-300`}
            enterFrom={tw`opacity-0`}
            enterTo={tw`opacity-100`}
            leave={tw`ease-in duration-200`}
            leaveFrom={tw`opacity-100`}
            leaveTo={tw`opacity-0`}
          >
            <Dialog.Overlay tw="fixed inset-0 bg-gray-500 bg-opacity-5 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span tw="w-96 float-right hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span> */}
          <Transition.Child
            as="div"
            tw="relative fixed bottom-0 w-11/12 sm:w-3/4 md:w-1/2 xl:w-1/4 h-3/4 xl:right-192 bg-white shadow-lg transform transition-all rounded-t-2xl "
            enter={tw`ease-out duration-300`}
            enterFrom={tw`opacity-0 translate-y-full`}
            enterTo={tw`opacity-100 translate-y-0`}
            leave={tw`ease-in duration-200`}
            leaveFrom={tw`opacity-100 translate-y-0`}
            leaveTo={tw`opacity-0 translate-y-full`}
          // tw="h-screen inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-lg sm:my-8 sm:align-middle max-w-lg w-full sm:p-6 transform transition-all max-h-90-screen"
          // enter={tw`ease-out duration-300`}
          // enterFrom={tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`}
          // enterTo={tw`opacity-100 translate-y-0 sm:scale-100`}
          // leave={tw`ease-in duration-200`}
          // leaveFrom={tw`opacity-100 translate-y-0 sm:scale-100`}
          // leaveTo={tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`}
          >
            <div tw="z-[2] sm:block absolute top-0 right-0 pt-1">
              <button
                type="button"
                tw="bg-white p-0.5 rounded-md text-gray-400 hover:(bg-gray-50 text-gray-500) focus:(outline-none bg-gray-50 text-gray-500)"
                onClick={onClose}
              >
                <span tw="sr-only">{t("shared.close")}</span>
                <XIcon tw="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div tw="h-full bottom-0 rounded-t-2xl mt-2">

              {authenticateCometChatStatus === "success" && initiateChatStatus === "success" && (
                <CometChatContextProvider user={chatWithUser}>
                  <main
                    tw="h-full flex-1 flex relative z-0 overflow-y-auto focus:outline-none xl:order-last rounded-t-2xl "
                    tabIndex={0}
                  >
                    <CometChatMessages
                      theme={theme}
                      lang={lang}
                      _parent="unified"
                      onMessageSent={(message) => {
                        messageSent({ userId: message.receiverId });
                      }}
                    />
                  </main>
                </CometChatContextProvider>
              )}



            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
