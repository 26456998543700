/** @jsxImportSource @emotion/react */
import { LocationMarkerIcon, UsersIcon, CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import qs from "qs";
import { useEffect, useState, Fragment } from "react";
import Select from 'react-select'
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { Combobox, Transition } from "@headlessui/react";
import "twin.macro";
import tw from "twin.macro";
import { useAuth, useRole } from "../auth/AuthContext";
import { Badge, BadgeDot } from "../shared/Badge";
import { Button, PrimaryButton, StatusButton } from "../shared/Button";
import { EmptyState, EmptyStateDescription, EmptyStateTitle } from "../shared/EmptyState";
import { List, ListItem, ListItemContent, ListItemLink } from "../shared/List";
import { Pagination } from "../shared/Pagination";
import { Skeleton } from "../shared/Skeleton";
import { useFormatDate } from "../shared/useFormatDate";
import { useSearchParams } from "../shared/useSearchParams";
import {
  useJobAdCountByStatus,
  useGetMyJobAdsTags,
  useGetMyJobAdsCities,
  useJobAdsQuery,
  useJobAdCandidatesCountersByIdQuery,
  useJobAdCandidatesCountersFromESQuery
} from "./JobAdAPI";

/** @type {(status: import("./JobAdAPI").JobAdStatus) => import("../shared/Badge").BadgeProps["color"]} */
const getJobAdStatusColor = (status) => {
  switch (status) {
    case "DRAFT":
      return "gray";
    case "PUBLISHED":
      return "indigo";
    case "EXPIRED":
      return "pink";
    case "CANCELED":
      return "red";
    case "DISABLED":
      return "gray";
    case "PUBLICATION_REQUEST":
      return "yellow";
    case "SHARED_METRO_TEMPLATES":
      return "red";
    default:
      return "gray";
  }
};

/** @type {React.FC<{ status: import("./JobAdAPI").JobAdStatus }>} */
export const JobAdStatusBadge = ({ status }) => {
  const { t } = useTranslation();
  const color = getJobAdStatusColor(status);
  return (
    <Badge color={color}>
      <BadgeDot />
      {t(`job-ad-status.${status}`)}
    </Badge>
  );
};

/** @type {import("react").FC<{ jobAd: import("./JobAdAPI").JobAd }>} */
const JobAdListItem = ({ jobAd, selected, jobAdExtension }) => {
  const { t } = useTranslation();
  const { page, adStatus, adTag, owner, city } = useSearchParams();
  // const { data: jobAdExtension, status: jobAdExtensionStatus } = useJobAdCandidatesCountersByIdQuery(jobAd.uuid);
  const formatDate = useFormatDate({ dateStyle: "short" });
  const [isHovering, setIsHovering] = useState(false);

  return (
    <ListItem
      active={selected === jobAd.uuid}>
      <ListItemLink
        to={{
          pathname: `/job-ads/${jobAd.uuid}`,
          search: qs.stringify({ page, adStatus, adTag, owner, city }, { addQueryPrefix: true }),
        }}
        active={selected}
      >
        <ListItemContent tw="block">
          <div tw="flex items-center justify-between space-x-2">
            <p tw="text-sm font-medium text-gray-900 truncate" title={jobAd.title}>
              {jobAd.title || <span tw="text-gray-300">{t("job-ads.title-placeholder")}</span>}
            </p>
            <JobAdStatusBadge status={jobAd.status} />
          </div>
          <div tw="flex items-center justify-between mt-2 space-x-2">
            <div tw="relative flex items-center"
            >
              <UsersIcon tw="flex-shrink-0 mr-1.5 h-3.5 w-3.5 text-gray-400" aria-hidden="true" />
              {jobAdExtension != null && jobAdExtension.totalCandidates != null ? (
                <p tw="text-xs text-gray-500 truncate">
                  {
                    jobAdExtension.totalCandidates > 0 ? (
                      <span>{t("job-ads.candidateCount", { newCount: jobAdExtension.appliedCandidates, count: jobAdExtension.totalCandidates })}</span>
                    ) : (
                      <span>{t("job-ads.noCandidateCount")}</span>
                    )
                  }
                  {
                    jobAdExtension.recruitedCandidates && jobAdExtension.recruitedCandidates > 0 ? (
                      // <span tw="text-green-500">  ({jobAdExtension.recruitedCandidates})</span>
                      <span
                        tw="inline-block ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        css={
                          tw`
                          bg-primary-600 text-white`
                        }
                      >
                        {jobAdExtension.recruitedCandidates}
                      </span>
                    ) : (<></>)
                  }
                </p>
              ) : (
                <p tw="text-xs text-gray-500 truncate">
                  <span>{t("job-ads.noCandidateCount")}</span>
                </p>
              )}
              {isHovering && (
                <div tw="absolute top-full right-0 mt-1 bg-gray-600 rounded text-white text-xs p-2">
                  <span tw="block">{t("hover.recruited-candidates", { count: jobAdExtension.recruitedCandidates })}</span>
                </div>
              )}
            </div>
            <p tw="text-xs text-gray-500 truncate">
              {jobAd.creationDate && `${formatDate(new Date(jobAd.creationDate))}`}
              {jobAd.expirationDate && ` - ${formatDate(new Date(jobAd.expirationDate))}`}
            </p>
          </div>

          {jobAd.location?.city && (
            <div tw="flex items-center justify-between mt-2 space-x-2">
              <div tw="flex">
                <LocationMarkerIcon
                  tw="flex-shrink-0 mr-1.5 h-3.5 w-3.5 text-gray-400"
                  aria-hidden="true"
                />
                <p tw="text-xs text-gray-500 truncate">{jobAd.location?.city}</p>
              </div>
            </div>
          )}
        </ListItemContent>
      </ListItemLink>
    </ListItem>
  );
};

/** @type {import("react").FC} */
const JobAdListItemSkeleton = () => {
  return (
    <ListItem>
      <ListItemContent>
        <div tw="flex-1 min-w-0">
          <Skeleton tw="w-48" />
          <Skeleton tw="w-32" />
        </div>
      </ListItemContent>
    </ListItem>
  );
};

export const StatusRing = ({ text }) => {
  return (
    <span
      tw="flex items-center justify-center ml-2 h-5 min-w-4 w-auto 
              rounded-full my-1 px-1 ring-2 ring-white font-size[0.7rem] font-medium bg-primary-500 text-white"
    >
      {text ? text : ""}
    </span>
  );
};

const JobAdList = () => {
  const { t } = useTranslation();
  const role = useRole();
  const hasOwnerEnabled = role === "ORGANIZATION_ADMIN";
  const { user } = useAuth();
  const { page = 1, size = 10, adStatus, adTag, owner, city } = useSearchParams();
  const history = useHistory();
  const searchParams = useSearchParams();

  const jobAdId =
    history.location.pathname?.split("/") && history.location.pathname?.split("/")[2]
      ? history.location.pathname?.split("/")[2]
      : null;

  const queryObj = {
    page: page - 1,
    size,
    status: [adStatus],
    tag: adTag,
    owner: owner,
    city: city,
    type: "MULTIPOSTED",
  };

  if (!adStatus || adStatus.length === 0) {
    delete queryObj.status;
  }

  if (!adTag || adTag.length === 0) {
    delete queryObj.tag;
  }

  if (!city) {
    delete queryObj.city
  }

  const { status: statusQuery, data, refetch } = useJobAdsQuery(queryObj);
  const organizationType = localStorage.getItem("organizationType");

  const matchesListRoute = useRouteMatch("/job-ads");
  let jobAdsStatus = ["", "PUBLISHED", "DRAFT", "EXPIRED", "DISABLED", "SHARED_METRO_TEMPLATES"];


  const { data: availableJobAdTags } = useGetMyJobAdsTags();
  const { data: availableCities } = useGetMyJobAdsCities();
  const [citiesoptions, setCitiesOptions] = useState([]);


  useEffect(() => {
    if (availableCities) {
      // Trick to search everywhere
      // availableCities.unshift({
      //   city: "Toutes les villes",
      //   counter: -1,
      //   default: true
      // })
      setSelectedCity(availableCities[0])
      let citiesoptionsTemp = [];
      availableCities.map((el) => { citiesoptionsTemp.push({ value: el.city, label: el.city }) })
      citiesoptionsTemp.sort((a, b) => a.value.localeCompare(b.value))
      setCitiesOptions(citiesoptionsTemp);
    }
  }, [availableCities])

  const [selectedCity, setSelectedCity] = useState(availableCities ? availableCities[0] : null)
  const [query, setQuery] = useState('')

  const handleSelectCity = (cityCounter) => {
    let toSearchParam = {
      ...searchParams,
      city: cityCounter.city
    };
    setSelectedCity(cityCounter);
    if (cityCounter.counter === -1) {
      delete toSearchParam.city;
    }
    history.push(
      `${history.location.pathname}${qs.stringify(
        toSearchParam,
        { addQueryPrefix: true }
      )}`
    );
  }
  const handleSelectCityOptions = (city) => {
    let toSearchParam = {
      ...searchParams,
    };
    delete toSearchParam.city;
    if (city) {
      toSearchParam.city = city.value
      setSelectedCity(city.value);
      // if (city.default) {
      //   delete toSearchParam.city;
      // }
    }
    history.push(
      `${history.location.pathname}${qs.stringify(
        toSearchParam,
        { addQueryPrefix: true }
      )}`
    );
  }

  const filteredCity =
    query === ''
      ? availableCities
      : availableCities.filter((cityCountery) => {
        return cityCountery.city.toLowerCase().includes(query.toLowerCase())
      })

  let tagOptions = [{ value: "", label: t("job-ads.all-tag") }];
  if (availableJobAdTags) {
    availableJobAdTags.forEach(el => {
      tagOptions.push({ value: el.uuid, label: el.name })
    })
  }


  // const citiesoptions = [
  //   { value: 'chocolate', label: 'Chocolzerezrezrrezrezrzeerzezate' },
  //   { value: 'strawberry', label: 'Strawberry' },
  //   { value: 'vanilla', label: 'Vanilla' }
  // ]
  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     ...tw`rounded-full w-full text-sm leading-5 bg-gray-100 text-gray-900 font-medium`,
  //     boxShadow: 'none', // Enlève l'ombre lors du focus
  //     border: 'none', // Enlève la bordure
  //     "&:hover": {
  //       border: 'none', // Enlève la bordure au survol
  //     },
  //   }),
  //   // Vous pouvez ajouter d'autres personnalisations de style ici si nécessaire
  // };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      clearIndicator: () => tw`text-gray-300 flex p-2 hover:text-gray-400 transition-colors`,
      container: () => tw`relative mt-1`,
      control: (_, state) => [
        tw`flex h-10 relative bg-white w-full border border-gray-300 rounded-md shadow-sm cursor-default text-sm`,
        state.isFocused && tw`outline-none ring-1 ring-primary-500 border-primary-500`,
        state.isDisabled && tw`opacity-50`,
      ],
      dropdownIndicator: () => tw`text-gray-300 flex p-2 hover:text-gray-400 transition-colors`,
      group: () => tw``,
      groupHeading: () => tw`text-gray-400 text-xs font-medium uppercase tracking-wide px-3 py-1.5`,
      indicatorsContainer: () => tw`flex flex-shrink-0 items-center self-stretch`,
      indicatorSeparator: () => tw`w-px my-2 bg-gray-300 self-stretch`,
      input: () => [tw`-my-px`, { "& input": tw`focus:ring-0 border-none` }],
      loadingIndicator: () =>
        tw`text-gray-300 flex p-2 hover:text-gray-400 transition-colors font-size[4px]`,
      loadingMessage: () => tw`text-center text-gray-400 py-2 px-3`,
      menu: () =>
        tw`absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md text-base ring-1 ring-black ring-opacity-5 text-sm`,
      menuList: () => tw`max-h-60 py-1 overflow-auto`,
      multiValue: () =>
        tw`inline-flex items-center py-0.5 pl-2 pr-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800`,
      multiValueLabel: () => tw`truncate`,
      multiValueRemove: () =>
        tw`flex-shrink-0 ml-0.5 h-4 w-4 rounded inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white`,
      noOptionsMessage: () => tw`text-center text-gray-400 py-2 px-3`,
      option: (_, state) => [
        tw`cursor-default select-none py-2 px-3 font-normal text-gray-900 block truncate`,
        state.isDisabled && tw`opacity-50`,
        state.isSelected && tw`font-semibold`,
        state.isFocused && tw`text-white bg-primary-600`,
      ],
      placeholder: () => tw`absolute top-1/2 transform -translate-y-1/2 text-gray-400`,
      singleValue: () => tw`block truncate text-sm text-gray-900`,
      valueContainer: () => tw`flex flex-wrap items-center flex-1 px-3 py-2 gap-1`,
    }),
    // Vous pouvez ajouter d'autres personnalisations de style ici si nécessaire
  };

  const Dropdown = ({
    options
  }) => {
    const [selectedTagOption, setSelectedTagOption] = useState(adTag ? adTag : options[0].value);
    return (
      <select tw="w-full inline-flex border-none items-center justify-center px-2 py-1 rounded-full text-sm font-medium text-gray-800 bg-gray-100 focus:outline-none focus:bg-gray-300 shadow-md"
        value={selectedTagOption}
        onChange={e => {
          setSelectedTagOption(e.target.value);
          if (e.target.value !== "") {
            delete searchParams.adTag;
            delete searchParams.adStatus;
            delete searchParams.page;
            history.push(
              `${history.location.pathname}${qs.stringify(
                {
                  ...searchParams,
                  adTag: e.target.value
                },
                { addQueryPrefix: true }
              )}`
            );
          } else {
            delete searchParams.adTag;
            delete searchParams.adStatus;
            delete searchParams.page;
            history.push(
              `${history.location.pathname}${qs.stringify(
                {
                  ...searchParams,
                },
                { addQueryPrefix: true }
              )}`
            );
          }
        }}>
        {options.map(o => (
          <option key={o.value} value={o.value}>{o.label}</option>
        ))}
      </select>
    );
  };

  let filtersForCount = {
    type: "MULTIPOSTED",
    owner: owner
  }
  if (adTag) filtersForCount.tag = adTag;
  if (city) filtersForCount.city = city;
  const { data: dataCountByStatus } = useJobAdCountByStatus(filtersForCount);
  let totalElements = 0;
  if (dataCountByStatus) {
    jobAdsStatus = jobAdsStatus.filter(
      (s) =>
        (dataCountByStatus[s] && dataCountByStatus[s] > 0) || dataCountByStatus[s] === undefined
    );
    for (let attribute in dataCountByStatus) {
      totalElements += dataCountByStatus[attribute];
    }
  }

  const [isChecked, setIsChecked] = useState(false);
  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    delete searchParams.owner;
    delete searchParams.page;

    if (!isChecked) {
      history.push(
        `${history.location.pathname}${qs.stringify(
          {
            ...searchParams,
            owner: "mine"
          },
          { addQueryPrefix: true }
        )}`
      );
    } else {
      history.push(
        `${history.location.pathname}${qs.stringify(
          {
            ...searchParams
          },
          { addQueryPrefix: true }
        )}`
      );
    }
  };

  const [jobAdExtensions, setJobAdExtensions] = useState([]);
  const { mutate: getJobAdCandidateCountersFromES, status: mutationStatus, error } = useJobAdCandidatesCountersFromESQuery();
  useEffect(() => {
    const fetchJobAdExtensions = async () => {
      if (data && data.content && data.content.length > 0) {
        const uuids = data.content.map(jobAd => jobAd.uuid);
        try {
          getJobAdCandidateCountersFromES({
            uuids
          }, {
            onSuccess: (extensions) => {
              setJobAdExtensions(extensions);
            }
          });
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchJobAdExtensions();
  }, [data, getJobAdCandidateCountersFromES]);

  const getJobAdExtensionForJobAd = (jobAdId) => {
    return jobAdExtensions.find((extension) => extension.uuid === jobAdId);
  };

  return (
    <aside
      tw="flex-shrink-0 border-r border-gray-200 bg-white"
      css={[
        matchesListRoute.isExact
          ? tw`flex flex-col w-full xl:w-96`
          : tw`hidden xl:w-96 xl:order-first xl:flex xl:flex-col`,
      ]}
    >
      <div tw="px-4 sm:px-6 py-5 flex items-start justify-between space-x-3">
        <div tw="space-y-1 w-full">
          <div tw="flex">
            {/* <h2 tw="text-lg leading-6 font-medium text-gray-900">
              {t("job-ads.search-directory-of-job-ads", {
                count: data ? data.totalElements : Number(size),
              })}
            </h2> */}
          </div>
          {data?.totalElements > 0 && (
            <div tw="flex flex-wrap gap-2">
              {jobAdsStatus.map((statusParam, index) => (
                <StatusButton
                  tw="shadow-md"
                  key={index}
                  active={
                    (!adStatus && statusParam === "") ||
                    (adStatus === "" && statusParam === "") ||
                    adStatus === statusParam
                  }
                  onClick={() => {
                    if (statusParam && statusParam.length > 0) {
                      delete searchParams.page;
                      history.push(
                        `${history.location.pathname}${qs.stringify(
                          {
                            ...searchParams,
                            adStatus: statusParam
                          },
                          { addQueryPrefix: true }
                        )}`
                      );
                    } else {
                      if (searchParams.adStatus) {
                        delete searchParams.adStatus;
                        delete searchParams.page;
                        history.push(
                          `${history.location.pathname}${qs.stringify(
                            {
                              ...searchParams,
                            },
                            { addQueryPrefix: true }
                          )}`
                        );
                      }
                    }
                  }}
                >
                  <span>
                    {statusParam === ""
                      ? t("job-ad-status.ALL")
                      : t(`job-ad-status.${statusParam}-plurial`)}
                  </span>

                  {statusParam === "" ? (
                    <StatusRing text={totalElements ? totalElements : ""} />
                  ) : (
                    <StatusRing
                      text={
                        dataCountByStatus && dataCountByStatus[statusParam] !== undefined
                          ? dataCountByStatus[statusParam]
                          : ""
                      }
                    />
                  )}
                </StatusButton>
              ))}
            </div>
          )}
          {hasOwnerEnabled && (user?.organizationPlan === "PREMIUM" || user?.organizationPlan === "SCHOOL") && (
            <div tw="flex w-full pt-2">
              <label for="toggleB" tw="flex items-center cursor-pointer">
                <div tw="relative">
                  <input type="checkbox" id="toggleB" tw="sr-only" checked={"mine" === owner} onChange={toggleCheckbox} />
                  <div id="background-dot" tw="block bg-gray-200 w-10 h-6 rounded-full"></div>
                  <div className="dot" tw="absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                </div>
                <div tw="ml-3 text-sm">
                  {t(`job-ads.only-my-job-ads`)}
                </div>
              </label>
              <style>
                {`
                  input:checked ~ .dot {
                  transform: translateX(100%);
                  background-color: #3B82F6;
                  }
                `}
              </style>
            </div>
          )}
          {(
            <div tw="flex flex-wrap gap-2 pt-2">
              {tagOptions.length > 1 && (
                <div tw="flex flex-wrap gap-2 w-full">
                  <Dropdown
                    options={tagOptions}
                  />
                </div>
              )}
            </div>
          )}
          {
            availableCities && (
              <>
                <div tw="pt-1">

                  <Select
                    tw="w-full text-sm leading-5 bg-gray-100 text-gray-900 focus:ring-0 font-medium"
                    styles={customStyles}
                    options={citiesoptions}
                    isClearable
                    isSearchable
                    defaultValue={city ? { label: city, value: city } : null}
                    placeholder={t("job-ads.all-cities")}
                    onChange={handleSelectCityOptions}
                    noOptionsMessage={(props) => t("shared.noOptions", props)} />
                </div>
              </>
            )

          }
        </div>
      </div>
      {/* Directory list */}
      <div tw="flex-1 min-h-0 relative overflow-y-auto border-t border-gray-200">
        {statusQuery === "error" && (
          <EmptyState>
            <EmptyStateTitle as="h3">{t("job-ads.failed-to-fetch-job-ads")}</EmptyStateTitle>
            <EmptyStateDescription>
              {t(
                "job-ads.we-could-not-fetch-your-job-ads-right-now-make-sure-that-you-are-connected-to-the-internet-or-try-again-later"
              )}
            </EmptyStateDescription>
            <Button onClick={refetch} tw="mt-8">
              {t("job-ads.try-again")}
            </Button>
          </EmptyState>
        )}
        {/* Loading skeleton state */}
        {statusQuery === "loading" && (
          <List>
            {Array.from({ length: 10 }, (_, index) => (
              <JobAdListItemSkeleton key={index} />
            ))}
          </List>
        )}
        {/* Empty state */}
        {statusQuery === "success" && data.content.length === 0 && (
          <EmptyState>
            <EmptyStateTitle as="h3">{t("job-ads.you-dont-have-any-job-ad-yet")}</EmptyStateTitle>
            <EmptyStateDescription>
              {t("job-ads.you-can-create-your-first-job-ad-by-clicking-on-the-button-below")}
            </EmptyStateDescription>
            {
              user?.organizationPlan === "METRO" ? (
                <PrimaryButton tw="mt-8" as={Link} to={`/templates/${searchParams.type}`}>
                  {t("shared.create")}
                </PrimaryButton>) : (
                <PrimaryButton tw="mt-8" as={Link} to={`/job-ads/new/${searchParams.type}`}>
                  {t("shared.create")}
                </PrimaryButton>
              )}
          </EmptyState>
        )}
        {/* List with job ads */}
        {statusQuery === "success" && data.content.length !== 0 && (
          <List>
            {data?.content.map((jobAd) => (
              <JobAdListItem
                key={jobAd.uuid}
                jobAd={jobAd}
                selected={jobAdId}
                jobAdExtension={getJobAdExtensionForJobAd(jobAd.uuid)} />
            ))}
          </List>
        )}
      </div>
      <div tw="mb-24 sm:mb-0">
        <Pagination
          page={Number(page)}
          pageSize={Number(size)}
          totalCount={data ? data.totalElements : Number(size)}
        />
      </div>
      {organizationType !== "SCHOOL" && (
          <div tw="fixed sm:(relative flex) inset-x-0 bottom-0 py-3 px-8 sm:px-6 bg-white border-t border-gray-200">
            {
              user?.organizationPlan === "METRO" ? (
                <PrimaryButton tw="w-full" as={Link} to={`/templates/${searchParams.type}`}>
                  {t("shared.create-jobad")}
                </PrimaryButton>) : (
                <PrimaryButton tw="w-full" as={Link} to={`/job-ads/new/${searchParams.type}`}>
                  {t("shared.create-jobad")}
                </PrimaryButton>
              )}
          </div>
      )}
    </aside >
  );
};

const JobAdListRoute = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t("job-ads.job-ads")} />
      <JobAdList />
    </>
  );
};

export default JobAdListRoute;
