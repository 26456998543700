/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import "twin.macro";
import tw from "twin.macro";
import { qualityLabel } from "../qualities/qualities";
import { EmptyState, EmptyStateDescription } from "../shared/EmptyState";
import { getTopQuestionnaireResults, useCandidateQuestionnaireResultsQuery, useUnauthenticatedCandidateQuestionnaireResultsQuery } from "./CandidateAPI";
import { upperFirst } from "../shared/upperFirst";

const Section = styled("section")(tw`space-y-1`);
const SectionTitle = styled("h2")(tw`text-sm font-medium text-gray-500`);

/** @type {(highlighted: import("../job-ads/JobAdAPI").Intelli7V2ScoringCriteria, quality: import("./CandidateAPI").QuestionnaireFormattedResult) => boolean} */
const isQualityHighlighted = (highlighted, quality) => {
  const scoreKey = quality.id.substring(0, 1).toLowerCase() + quality.id.substring(1);
  return highlighted[scoreKey] === true;
};

/** @type {React.FC<{ title: string, results: import("./CandidateAPI").QuestionnaireFormattedResult[], highlighted?: import("../job-ads/JobAdAPI").Intelli7V2ScoringCriteria }>} */
const QuestionnaireResultCategory = ({ title, results, highlighted }) => {
  const { t } = useTranslation();
  return (
    <li tw="rounded-lg overflow-hidden border border-gray-300 bg-white shadow-sm divide-y divide-gray-300">
      <header tw="px-4 py-5 sm:px-6">
        <h3 tw="text-sm font-medium">{title}</h3>
      </header>
      <ul tw="bg-white px-4 sm:px-6 divide-y divide-gray-200">
        {results.map((result) => (
          <li key={result.id} tw="relative flex items-center justify-between py-4">
            {highlighted && isQualityHighlighted(highlighted, result) && (
              <div tw="absolute -left-3 -right-3 top-2 bottom-2 bg-green-100 rounded-md" />
            )}
            <div tw="relative flex flex-col flex-1">
              <span tw="text-sm font-medium text-gray-900">{t(qualityLabel[`${upperFirst(result.title)}`])}</span>
              <span tw="text-sm text-gray-500">
                {t("qualities.benchmark-result", { benchmark: result.benchmark })}
              </span>
            </div>
            <div
              tw="relative flex-shrink-0 rounded h-8 w-8 flex items-center justify-center font-medium leading-none text-white"
              css={[
                result.benchmark === null && tw`bg-gray-500`,
                result.benchmark !== null && result.score < result.benchmark && tw`bg-red-500`,
                result.benchmark !== null && result.score >= result.benchmark && tw`bg-green-500`,
              ]}
            >
              {result.score}
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};

const AuthenticatedResultList = ({ candidateId, empty, highlighted }) => {
  const { t } = useTranslation();
  const { status, data } = useCandidateQuestionnaireResultsQuery(candidateId);

  const part1Result = getTopQuestionnaireResults(data, "part1");
  const part2Result = getTopQuestionnaireResults(data, "part2");
  const part3Result = getTopQuestionnaireResults(data, "part3");

  return (
    <>
      {/* {status === "success" && data === null && (
        <EmptyState tw="bg-gray-100 rounded-lg">
          <EmptyStateDescription tw="mt-0">{empty}</EmptyStateDescription>
        </EmptyState>
      )} */}
      {status === "success" && data !== null && (
        <Section>
          <SectionTitle>{t("candidates.qualities")}</SectionTitle>
          <ul tw="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {part1Result && (
              <QuestionnaireResultCategory
                title={t("qualitiesV2.individual")}
                results={part1Result}
                highlighted={highlighted}
              />
            )}
            {part2Result && (
              <QuestionnaireResultCategory
                title={t("qualitiesV2.relational")}
                results={part2Result}
                highlighted={highlighted}
              />
            )}
            {part3Result && (
              <QuestionnaireResultCategory
                title={t("qualitiesV2.operational")}
                results={part3Result}
                highlighted={highlighted}
              />
            )}
          </ul>
        </Section>
      )}
    </>
  );
};

const UnauthenticatedResultList = ({ candidateId, empty, highlighted }) => {
  const { t } = useTranslation();
  const { status, data } = useUnauthenticatedCandidateQuestionnaireResultsQuery(candidateId);

  const part1Result = getTopQuestionnaireResults(data, "part1");
  const part2Result = getTopQuestionnaireResults(data, "part2");
  const part3Result = getTopQuestionnaireResults(data, "part3");

  return (
    <>
      {/* {status === "success" && data === null && (
        <EmptyState tw="bg-gray-100 rounded-lg">
          <EmptyStateDescription tw="mt-0">{empty}</EmptyStateDescription>
        </EmptyState>
      )} */}
      {status === "success" && data !== null && (
        <Section>
          <SectionTitle>{t("candidates.qualities")}</SectionTitle>
          <ul tw="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {part1Result && (
              <QuestionnaireResultCategory
                title={t("qualitiesV2.individual")}
                results={part1Result}
                highlighted={highlighted}
              />
            )}
            {part2Result && (
              <QuestionnaireResultCategory
                title={t("qualitiesV2.relational")}
                results={part2Result}
                highlighted={highlighted}
              />
            )}
            {part3Result && (
              <QuestionnaireResultCategory
                title={t("qualitiesV2.operational")}
                results={part3Result}
                highlighted={highlighted}
              />
            )}
          </ul>
        </Section>
      )}
    </>
  );
};

export const QuestionnaireSection = ({ candidateId, empty, highlighted, source = "AUTHENTICATED" }) => {

  return (
    <>
      {
        "AUTHENTICATED" === source && (
          <AuthenticatedResultList candidateId={candidateId} empty={empty} highlighted={highlighted}></AuthenticatedResultList>
        )
      }
      {
        "UNAUTHENTICATED" === source && (
          <UnauthenticatedResultList candidateId={candidateId} empty={empty} highlighted={highlighted}></UnauthenticatedResultList>
        )
      }
    </>
  );
};
