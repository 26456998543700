/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import "twin.macro";
import { useTranslation } from "react-i18next";
import { JobAdMatchingScore, JobAdMatchingScoreBadge } from "./JobAdMatchingScore";
import { maxHeight } from "tailwindcss/defaultTheme";

export const JobAdMatchingCustomMatchingGlobalCircleForList = ({ score }) => {
  let color = "#6B7280";
  if (score !== null) {
    if (score > 70) {
      color = '#10B981'
    } else if (score > 50) {
      color = '#3B82F6'
    }
  }
  return (
    <JobAdMatchingCustomMatchingCircle size="45px" color={color} fontSize={'0.75rem'} score={score} />
  )
};

export const JobAdMatchingCustomMatchingGlobalCircleForTinyList = ({ score }) => {
  let color = "#6B7280";
  if (score !== null) {
    if (score > 70) {
      color = '#10B981'
    } else if (score > 50) {
      color = '#3B82F6'
    }
  }
  return (
    <JobAdMatchingCustomMatchingCircle size="35px" color={color} fontSize={'0.60rem'} score={score} />
  )
};

export const JobAdMatchingCustomMatchingGlobalCircleForHeader = ({ name, score, size = "80px", fontSize = '1.2rem', ...props }) => {
  let color = "#6B7280";
  let secondaryColor = "#F3F4F6"
  if (score !== null) {
    if (score > 70) {
      color = '#10B981'
      secondaryColor = '#D1FAE5'
    } else if (score > 50) {
      color = '#3B82F6'
      secondaryColor = '#DBEAFE'
    }
  }
  return (
    <JobAdMatchingCustomMatchingGlobalCircle size={size} color={color} fontSize={fontSize} secondaryColor={secondaryColor} name={name} score={score} {...props} />
  )
};

const JobAdMatchingCustomMatchingGlobalCircle = ({ name, size = '80px', fontSize = '1.2rem', color, secondaryColor, score, ...props }) => {
  const radius = 35; // Adjusted radius
  const circumference = 2 * Math.PI * radius;
  const strokeLength = score !== null ? (circumference * score) / 100 : 0;
  const strokeGap = circumference - strokeLength;

  return (
    <div tw="flex flex-col items-center">
      <div
        tw="flex-shrink-0 flex items-center justify-center overflow-hidden relative rounded-lg p-1"
        style={{ height: size, width: size, backgroundColor: secondaryColor}}  // Adjusted dimensions
        {...props}
      >
        <svg tw="w-full h-full" viewBox="0 0 80 80">
          <circle
            cx="40"  // Adjusted center x
            cy="40"  // Adjusted center y
            r={radius}
            fill="#fff"
            stroke={color}
            strokeWidth="5"
            strokeDasharray={`${strokeLength} ${strokeGap}`}
            transform="rotate(-90 40 40)"  // Adjusted rotation center
          />
        </svg>
        {
          score === null ? (
            <span tw="text-lg font-semibold leading-none absolute" style={{ color, fontSize }}>N/C</span>
          ) : (
            <span tw="text-lg font-semibold leading-none absolute" style={{ color, fontSize }}>{score}%</span>
          )
        }
      </div>
      {name && <div tw="text-xs" style={{ color }}>{name}</div>}
    </div>
  );
};

const JobAdMatchingCustomMatchingCircle = ({ name, size = '60px', color = 'green', fontSize = '0.875rem', score, ...props }) => {
  const radius = 25; // Adjusted radius
  const circumference = 2 * Math.PI * radius;
  const strokeLength = score !== null ? (circumference * score) / 100 : 0;
  const strokeGap = circumference - strokeLength;

  return (
    <div tw="mx-1 flex flex-col items-center">
      <div
        tw="flex-shrink-0 flex items-center justify-center overflow-hidden relative"
        style={{ height: size, width: size, maxHeight: size, maxWidth: size }}  // Adjusted dimensions
        {...props}
      >
        <svg tw="w-full h-full" viewBox="0 0 60 60">
          <circle
            cx="30"  // Adjusted center x
            cy="30"  // Adjusted center y
            r={radius}
            fill="none"
            stroke={color}
            strokeWidth="5"
            strokeDasharray={`${strokeLength} ${strokeGap}`}
            transform="rotate(-90 30 30)"  // Adjusted rotation center
          />
        </svg>
        {
          score === null ? (
            <span tw="text-sm font-semibold leading-none absolute" style={{ color, fontSize }}>N/C</span>
          ) : (
            <span tw="text-sm font-semibold leading-none absolute" style={{ color, fontSize }}>{score}%</span>
          )
        }

      </div>
      <div tw="text-xs text-center" style={{ color }}>{name}</div>
    </div>
  );
};

const JobAdCustomMatchingScoresByPosition = ({ jobAdCandidate, position, jobAd, size = '60px', fontSize = '0.875rem' }) => {
  const { t } = useTranslation();
  return (
    <>
      {
        jobAd.matchingHardSkillsPosition != null && jobAd.matchingHardSkillsPosition === position && (
          <JobAdMatchingCustomMatchingCircle name={t("job-ads.custom-matching-hardskills-light")} score={jobAdCandidate.skillsMatchingScoreCalculated} color="#3C86FE" size={size} fontSize={fontSize} />
        )
      }
      {
        jobAd.matchingSoftSkillsPosition != null && jobAd.matchingSoftSkillsPosition === position && (
          <JobAdMatchingCustomMatchingCircle name={t("job-ads.custom-matching-softskills-light")} score={jobAdCandidate.intelli7MatchingScoreCalculated} color="#FF3C73" size={size} fontSize={fontSize} />
        )
      }
      {
        jobAd.matchingAccreditationsDiplomaPosition != null && jobAd.matchingAccreditationsDiplomaPosition === position && (
          <JobAdMatchingCustomMatchingCircle name={t("job-ads.custom-matching-accreditations-light")} score={jobAdCandidate.accreditationsMatchingScoreCalculated} color="#F6C159" size={size} fontSize={fontSize} />
        )
      }
      {
        jobAd.matchingAvailabilitiesPosition != null && jobAd.matchingAvailabilitiesPosition === position && (
          <JobAdMatchingCustomMatchingCircle name={t("job-ads.custom-matching-availabilities-light")} score={jobAdCandidate.availabilitiesMatchingScoreCalculated} color="#53CE8E" size={size} fontSize={fontSize} />
        )
      }
      {
        jobAd.matchingLicencesPosition != null && jobAd.matchingLicencesPosition === position && (
          <JobAdMatchingCustomMatchingCircle name={t("job-ads.custom-matching-licences-light")} score={jobAdCandidate.licencesMatchingScoreCalculated} color="#00DBFF" size={size} fontSize={fontSize} />
        )
      }
      {
        jobAd.matchingExperiencesPosition != null && jobAd.matchingExperiencesPosition === position && (
          <JobAdMatchingCustomMatchingCircle name={t("job-ads.custom-matching-experiences-light")} score={jobAdCandidate.experiencesMatchingScoreCalculated} color="#9459ff" size={size} fontSize={fontSize} />
        )
      }
    </>
  )
}

export const JobAdCustomMatchingScores = ({ jobAdCandidateStatus, jobAdCandidate, jobAd }) => {
  const { t } = useTranslation();
  const positions = [1, 2, 3, 4, 5, 6];

  return (
    <div tw="flex flex-wrap justify-center items-center space-x-2">
      {jobAdCandidateStatus === "loading" && (
        <>
          <span tw="flex-shrink-0 h-10 w-10 rounded-full ring ring-gray-100 bg-gray-100 animate-pulse" />
          <div tw="space-y-1 flex flex-col items-start">
            <div tw="h-5 w-32 rounded-full bg-gray-100 animate-pulse" />
            <div tw="h-5 w-32 rounded-full bg-gray-100 animate-pulse" />
          </div>
        </>
      )}
      {jobAdCandidateStatus === "success" && (
        <>
          <div tw="hidden sm:flex">
            <JobAdMatchingCustomMatchingGlobalCircleForHeader tw="h-20 w-20" name={"Total"} score={jobAdCandidate.globalMatchingScore} size={"8Opx"} fontSize={"1.2rem"} />
            {
              positions.map((el, index) => (
                <>
                  <JobAdCustomMatchingScoresByPosition position={el} jobAdCandidate={jobAdCandidate} jobAd={jobAd} size={"60px"} fontSize={"0.875rem"} />
                </>
              ))
            }
          </div>
          <div tw="flex sm:hidden">
            <JobAdMatchingCustomMatchingGlobalCircleForHeader tw="h-16 w-16" score={jobAdCandidate.globalMatchingScore} size={"50px"} fontSize={"0.8rem"} />
            {
              positions.map((el, index) => (
                <>
                  <JobAdCustomMatchingScoresByPosition position={el} jobAdCandidate={jobAdCandidate} jobAd={jobAd} size={"35px"} fontSize={"0.5rem"} />
                </>
              ))
            }
          </div>
        </>
      )}
    </div>
  );
};
