/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormProvider, useController, useForm, useFormContext, useWatch } from "react-hook-form";
import { useFetchJobs } from "../jobs/JobAPI";
import { useJobAdsBySharedTemplateQuery, useDuplicateJobAdMutation } from "../job-ads/JobAdAPI";
import { PrimaryButton } from "../shared/Button"
import "twin.macro";
import {
  AsyncSelectBase,
  useFieldError,
  ErrorMessage,
  FormGroup,
  Label,
} from "../shared/Form";
import { useLanguage } from "../shared/useLanguage";


const JobAutocomplete = ({ name, rules, ...props }) => {
  const { t } = useTranslation();
  const { setValue, control } = useFormContext();
  const jobCode = useWatch({ name: "jobCode" });
  const {
    field: { value: jobTitle, onChange: onJobTitleChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue: "",
    control,
  });

  const selectedOption = jobCode && jobTitle ? { name: jobTitle, code: jobCode } : null;
  const onOptionChange = (selectedOption) => {
    if (selectedOption) {
      setValue("jobCode", selectedOption.code);
      onJobTitleChange(selectedOption.name);
    } else {
      setValue("jobCode", null);
      onJobTitleChange(null);
    };
    props.onSelectionChange(selectedOption);
  }

  const fetchJobs = useFetchJobs();
  const lang = useLanguage();
  const loadOptions = async (input) => fetchJobs({ input, lang, size: 20 });
  const error = useFieldError(name);

  return (
    <AsyncSelectBase
      loadOptions={loadOptions}
      getOptionLabel={(job) => job.name}
      getOptionValue={(job) => job.code}
      value={selectedOption}
      onChange={onOptionChange}
      isInvalid={!!error}
      defaultOptions
      placeholder={t("job-ads.job-placeholder")}
      loadingMessage={() => t("shared.loading")}
      noOptionsMessage={(props) => t("shared.noOptions", props)}
      {...controllerProps}
      {...props}
    />
  );
};


export const Templates = () => {
  const { t } = useTranslation();
  const queryObj = {
    page: 0,
    size: 20,
  };

  const [adTemplates, setAdTemplates] = useState([]);
  const { mutate: duplicateJobAd } = useDuplicateJobAdMutation();
  const { status: statusQuery, data, refetch } = useJobAdsBySharedTemplateQuery(queryObj);


  // Utilisez useState pour gérer l'état d'affichage du bouton d'action sur mobile
  const [activeIndex, setActiveIndex] = useState(null);

  // Gère le clic pour les appareils mobiles
  const handleMobileClick = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const onDuplicate = async (jobAd) => {
    const location = {
      latitude: null,
      longitude: null,
      placeId: null,
      address: null,
      city: null,
      zipCode: null,
      countryCode: null,
    };
    await duplicateJobAd({ jobAdUuid: jobAd.uuid, location: location, step: "information" });
  };
  const formProps = useForm({ defaultValues: {} });
  // Ajoutez un état pour stocker la valeur sélectionnée
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    if (data) {
      if (selectedJob) {
        setAdTemplates(data.content.filter(item => item.jobCode === selectedJob.code));
      } else {
        setAdTemplates(data.content);
      }
    }
  }, [data, selectedJob]);

  // Définissez une fonction pour gérer la nouvelle sélection
  const handleJobSelectionChange = (selectedOption) => {
    // Faites ce que vous voulez avec la valeur sélectionnée ici
    // Par exemple, stocker la valeur dans l'état
    setSelectedJob(selectedOption);
    console.log(selectedOption);
  };

  return (
    <main tw="flex-1 max-h-screen overflow-y-auto pl-4 pr-4 sm:pl-12 sm:pr-12">
      <h1 tw="text-2xl font-extrabold text-gray-900 pt-5 sm:pt-12">{t("templates.title")}</h1>

      <FormProvider {...formProps}>
        <form>
          <FormGroup>
            {/* <Label>
              {t("job-ads.job")}
            </Label> */}
            <JobAutocomplete name="jobTitle" isClearable onSelectionChange={handleJobSelectionChange} />
            <ErrorMessage name="jobTitle" />
          </FormGroup>
        </form>
      </FormProvider>
      {
        statusQuery === "success" && adTemplates.length > 0 && (
          <div tw="flex flex-wrap justify-center gap-4 md:gap-8 lg:gap-12 mt-8 mb-24 sm:mb-16">
            {adTemplates.map((jobAd, index) => (
              <div key={index} tw="flex flex-col items-center max-w-[150px] md:max-w-[200px] lg:max-w-[250px] flex-grow relative">
                <div
                  tw="w-[150px] md:w-[200px] lg:w-[250px] h-[150px] md:h-[200px] lg:h-[250px] pb-[100%] relative"
                  onClick={() => handleMobileClick(index)}
                  onMouseEnter={() => setActiveIndex(index)}
                  onMouseLeave={() => setActiveIndex(null)}
                >
                  <img src={jobAd.picUrl} tw="absolute inset-0 w-full h-full object-cover rounded-xl" alt={jobAd.title} />
                  {(activeIndex === index) && (
                    <div tw="absolute inset-0 bg-black bg-opacity-30 flex justify-center items-center rounded-xl">
                      <PrimaryButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onDuplicate(jobAd);
                        }}
                      >
                        {t("shared.create-jobad")}
                      </PrimaryButton>
                    </div>
                  )}
                </div>
                <h2 tw="text-sm lg:text-lg font-semibold mt-2 text-center">{jobAd.title}</h2>
              </div>
            ))}
          </div>
        )
      }
      {
        selectedJob && adTemplates.length == 0 && (
          <div tw="flex justify-center items-center">
            <div tw="flex flex-col justify-center items-center mt-8 mb-16 border rounded-lg p-8 max-w-[400px]">
              <span tw="text-base mb-8 text-center">{t("templates.create-selected-job", { selectedName: selectedJob.name })}</span>
              <PrimaryButton
                as={Link} to={`/job-ads/new/?jobcode=${selectedJob.code}&title=${selectedJob.name}`}
              >
                {t("shared.create-jobad")}
              </PrimaryButton>
            </div>
          </div>
        )
      }
    </main >
  );
};
