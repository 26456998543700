// @ts-check
import { useMutation, useQuery } from "react-query";
import i18n from "../i18n";
import { useFetchTranslatedJobTitleByJobCode } from "../jobs/JobAPI";
import { useCandidateAPI, useMediaAPI } from "../shared/useAPI";
import { useFetchTranslatedActivities, useFetchTranslatedSkills } from "../skills/SkillAPI";

/** @typedef {'FR' | 'EN' | 'DE' | 'NL' | 'ES' | 'IT' | 'PT'} Language */

/**
 * @typedef Candidate
 * @property {string} uuid the candidate uuid
 * @property {string} creationDate the candidate creation date as a ISO8601 date
 * @property {{ countryIsoCode: string, number: string }} [phone] the candidate phone number
 * @property {string} [email] the candidate email address
 * @property {string} [firstname] the candidate first name
 * @property {string} [lastname] the candidate last name
 * @property {'MALE' | 'FEMALE' | 'OPT_OUT'} [gender] the candidate gender
 * @property {Language} [language] the candidate language code
 * @property {string} [birthdate] the candidate birth date (YYYY-MM-DD
 */

/** @typedef {'MONDAY'|'TUESDAY'|'WEDNESDAY'|'THURSDAY'|'FRIDAY'|'SATURDAY'|'SUNDAY'} WeekDay */
/** @typedef {{ morning: boolean, afternoon: boolean, evening: boolean }} Availability */
/** @typedef {{[key in WeekDay]: Availability}} Availabilities */

/**
 * @typedef Skill
 * @property {number} eval
 * @property {boolean} highlighted
 * @property {number} id
 * @property {string} name
 * @property {string} graduationDate
 */
/**
 * @typedef CandidateSkills
 * @property {Skill[]} accreditations
 * @property {Skill[]} activities
 * @property {Skill[]} skills
 */

/**
 * @typedef JobookLabel
 * @property {string} [fallback]
 * @property {{ [key in Language]?: string }} [labels]
 */

/**
 * @typedef DriverLicences
 * @property {boolean} hasLicenceA
 * @property {boolean} hasLicenceB
 * @property {boolean} hasLicenceC
 * @property {boolean} hasLicenceCE
 * @property {boolean} hasLicenceC1
 * @property {boolean} hasLicenceC1E
 * @property {boolean} hasLicenceD
 * @property {boolean} hasLicenceDE
 * @property {boolean} hasLicenceD1
 * @property {boolean} hasLicenceD1E
 * @property {boolean} hasVehicle
 */

/**
 * @typedef Experience
 * @property {string} company
 * @property {number} companyWorkforce
 * @property {string} from
 * @property {string} jobCode
 * @property {string} sector
 * @property {string} title
 * @property {string} to
 * @property {string} uuid
 */

/**
 * @typedef Mobility
 * @property {number} latitude
 * @property {number} longitude
 * @property {number} radiusInKm
 */

/**
 * @typedef SpokenLanguage
 * @property {string} languageIsoCode
 * @property {'levelA' | 'levelB' | 'levelC'} level
 */

/**
 * @typedef CandidateDetails
 * @property {Availabilities} [availabilities]
 * @property {CandidateSkills} [candidateSkills]
 * @property {string} candidateUuid
 * @property {JobookLabel} [customResumeTitle]
 * @property {DriverLicences} [driverLicences]
 * @property {boolean} [employed]
 * @property {Experience[]} experiences
 * @property {string} [lastDiploma]
 * @property {Mobility} [mobility]
 * @property {SpokenLanguage[]} spokenLanguages
 * @property {{ code?: string, name?: string }} [job]
 * @property {string} personalResumeExtension
 */

 /**
  * 
  * @typedef CandidateOrganizationNote
  * @property {string} UUID
  * @property {string} organizationUuid
  * @property {string} candidateUuid
  * @property {string} ownerUuid
  * @property {string} content
  * @property {string} creationDate
  * @property {string} lastUpdateDate
  */

/** @type {(candidateId: string) => import("react-query").UseQueryResult<string>} */
export const useCandidatePictureURLQuery = (candidateId) => {
  const api = useMediaAPI();
  return useQuery(
    `public/candidate/pic/${candidateId}.jpeg`,
    async () => {
      try {
        const response = await api.get(`public/candidate/pic/${candidateId}.jpeg`);
        const blob = await response.blob();
        return blob ? URL.createObjectURL(blob) : null;
      } catch (e) {
        if (e.name === "APIError") {
          if (e.payload.status === 404 || e.payload.status === 403) {
            return null;
          }
        }
        throw e;
      }
    },
    {
      enabled: !!candidateId,
    }
  );
};

/** @type {() => import("react-query").UseMutationResult<Response, unknown, { candidateUuid: string }>} */
export const useIntelli7ExportMutation = () => {
  const api = useCandidateAPI();
  return useMutation({
    mutationFn: async (candidateUuid) => api.put(`api/domain/candidates/intelli7export/${candidateUuid}`),
    onSuccess: async () => {
    },
    onError: async() => {

    }
  });
};

/** @type {(candidateId: string) => import("react-query").UseQueryResult<string>} */
export const useCandidateVideoURLQuery = (candidateId) => {
  const api = useMediaAPI();
  return useQuery(
    `public/candidate/video/${candidateId}.mp4`,
    async () => {
      try {
        const response = await api.get(`public/candidate/video/${candidateId}.mp4`);
        const blob = await response.blob();
        return blob
          ? `${process.env.REACT_APP_MEDIA_API_URL}/public/candidate/video/${candidateId}.mp4`
          : null;
      } catch (e) {
        if (e.name === "APIError") {
          if (e.payload.status === 404 || e.payload.status === 403) {
            return null;
          }
        }
        throw e;
      }
    },
    {
      enabled: !!candidateId,
    }
  );
};

/** @type {() => import("react-query").UseMutationResult<>} */
export const useCandidateResumeMutation = () => {
  const mediaAPI = useMediaAPI();
  return useMutation({
    mutationFn: async ({candidate, extension}) => {
      const blob = await mediaAPI.get(`public/candidate/personalresume/${candidate.uuid}`).blob();
      let link = document.createElement('a');
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', `${candidate.firstname}_${candidate.lastname}_CV.${extension}`);
      link.click();
      // window.location.href = URL.createObjectURL(blob);
    }
  });
};



/** @type {() => (experiences: Experience[]) => Promise<Experience[]>} */
const useFetchTranslatedExperiences = () => {
  const fetchTranslatedJobTitleByJobCode = useFetchTranslatedJobTitleByJobCode();
  return (experiences) => {
    return Promise.all(
      experiences.map(async (experience) => ({
        ...experience,
        title: experience.jobCode
          ? (await fetchTranslatedJobTitleByJobCode(experience.jobCode)) ||
            // Fallback to experience title if the API fails to respond
            experience.title
          : experience.title,
      }))
    );
  };
};

export const useFetchCandidateById = () => {
  const candidateAPI = useCandidateAPI();
  const fetchTranslatedExperiences = useFetchTranslatedExperiences();
  const fetchTranslatedJobTitleByJobCode = useFetchTranslatedJobTitleByJobCode();
  const fetchTranslatedSkills = useFetchTranslatedSkills();
  const fetchTranslatedActivities = useFetchTranslatedActivities();
  return async (candidateId) => {
    /** @type {[Candidate, CandidateDetails]} */
    const [candidate, candidateDetails] = await Promise.all([
      candidateAPI.get(`api/domain/candidates/${candidateId}`).json(),
      candidateAPI
        .get(`api/candidate/${candidateId}/details`)
        .json()
        .catch((err) => {
          // Response might be empty (causing a SyntaxError with an "Unexpected end of JSON input")
          if (err instanceof SyntaxError) {
            return {};
          }
          throw err;
        }),
    ]);
    if (candidate && candidateDetails) {
      // Translate candidate experience jobs
      if (candidateDetails.experiences) {
        candidateDetails.experiences = await fetchTranslatedExperiences(
          candidateDetails.experiences
        );
      }
      // Translate candidate job name if it has a job code (i.e.: it is not a custom job title)
      if (candidateDetails.job && candidateDetails.job.code) {
        candidateDetails.job = {
          ...candidateDetails.job,
          name:
            (await fetchTranslatedJobTitleByJobCode(candidateDetails.job.code)) ||
            // Fallback to job name if the API fails to respond
            candidateDetails.job.name,
        };
      }
      if (candidateDetails.candidateSkills && candidateDetails.candidateSkills.skills) {
        candidateDetails.candidateSkills.skills = await fetchTranslatedSkills(
          candidateDetails.candidateSkills.skills
        );
      }
      if (candidateDetails.candidateSkills && candidateDetails.candidateSkills.activities) {
        candidateDetails.candidateSkills.activities = await fetchTranslatedActivities(
          candidateDetails.candidateSkills.activities
        );
      }
      if (candidateDetails.candidateSkills && candidateDetails.candidateSkills.accreditations) {
        candidateDetails.candidateSkills.accreditations = await fetchTranslatedActivities(
          candidateDetails.candidateSkills.accreditations
        );
      }
      return { candidate, candidateDetails };
    } else {
      // Make sure that both candidate and candidate details are present
      return null;
    }
  };
};

/** @type {(candidateUuid: string, source: string) => import("react-query").UseQueryResult<{ candidateDetails: CandidateDetails }>} */
export const useCandidateDetailsByUuidQuery = (candidateUuid, source) => {
  const fetchCandidateDetailsByUuid = useCandidateDetailsByUuid();
  return useQuery(
    `api/candidate/${candidateUuid}/details?source=${source}`,
    async () => fetchCandidateDetailsByUuid(candidateUuid, source),
    {
      enabled: !!candidateUuid,
    }
  );
};

export const useCandidateDetailsByUuid = () => {
  const api = useCandidateAPI();
  const fetchTranslatedExperiences = useFetchTranslatedExperiences();
  const fetchTranslatedJobTitleByJobCode = useFetchTranslatedJobTitleByJobCode();
  const fetchTranslatedSkills = useFetchTranslatedSkills();
  const fetchTranslatedActivities = useFetchTranslatedActivities();
  return async (candidateUuid, source) => {
    const candidateDetails = await
      api.get(`api/candidate/${candidateUuid}/details?source=${source}`)
        .json();

    if (candidateDetails) {
      // Translate candidate experience jobs
      if (candidateDetails.experiences) {
        candidateDetails.experiences = await fetchTranslatedExperiences(
          candidateDetails.experiences
        );
      }
      // Translate candidate job name if it has a job code (i.e.: it is not a custom job title)
      if (candidateDetails.job && candidateDetails.job.code) {
        candidateDetails.job = {
          ...candidateDetails.job,
          name:
            (await fetchTranslatedJobTitleByJobCode(candidateDetails.job.code)) ||
            // Fallback to job name if the API fails to respond
            candidateDetails.job.name,
        };
      }
      if (candidateDetails.candidateSkills && candidateDetails.candidateSkills.skills) {
        candidateDetails.candidateSkills.skills = await fetchTranslatedSkills(
          candidateDetails.candidateSkills.skills
        );
      }
      if (candidateDetails.candidateSkills && candidateDetails.candidateSkills.activities) {
        candidateDetails.candidateSkills.activities = await fetchTranslatedActivities(
          candidateDetails.candidateSkills.activities
        );
      }
      if (candidateDetails.candidateSkills && candidateDetails.candidateSkills.accreditations) {
        candidateDetails.candidateSkills.accreditations = await fetchTranslatedActivities(
          candidateDetails.candidateSkills.accreditations
        );
      }
      return candidateDetails;
    } else {
      // Make sure that both candidate and candidate details are present
      return null;
    }
  };

};

/** @type {(candidateId: string) => import("react-query").UseQueryResult<{ candidate: Candidate, candidateDetails: CandidateDetails }>} */
export const useCandidateByIdQuery = (candidateId) => {
  const fetchCandidateById = useFetchCandidateById();
  return useQuery(
    `api/domain/candidates/${candidateId}`,
    async () => fetchCandidateById(candidateId),
    {
      enabled: !!candidateId,
    }
  );
};


/** @type {(candidateUuid: string) => import("react-query").UseQueryResult<Array<CandidateOrganizationNote>>} */
export const useCandidatesOrganizationNoteQuery = (candidateUuid) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `api/organization/candidate/${candidateUuid}/notes`,
    queryFn: async () => api.get(`api/organization/candidate/${candidateUuid}/notes`).json(),
    enabled: !!candidateUuid
  });
};

/** @type {() => import("react-query").UseMutationResult<void, unknown, string>} */
export const usePostCandidateNoteMutation = () => {
  const candidateAPI = useCandidateAPI();
  return useMutation({
    mutationFn: async (note) => {
      const result = await candidateAPI
        .post(`api/organization/candidate/notes`, {
          json: note
        })
        .json();
      return result;
    },
    onSuccess: () => {
    }
  });
};

export const useDeleteCandidateNoteMutation = () => {
  const candidateAPI = useCandidateAPI();
  return useMutation({
    mutationFn: async (noteUuid) => {
      const result = await candidateAPI.delete(`api/organization/candidate/notes/${noteUuid}`).json();
      return result;
    }
  });
};


/** @type {(candidate: { firstname?: string, lastname?: string, email?: string, phone?: { number: string }} | null) => string | undefined} */
export const getDisplayName = (candidate) => {
  if (!candidate) {
    return undefined;
  }
  if (candidate.firstname && candidate.lastname) {
    return `${candidate.firstname} ${candidate.lastname}`;
  }
  if (candidate.email) {
    return candidate.email;
  }
  if (candidate.phone?.number) {
    return candidate.phone.number;
  }
  return undefined;
};

/** @type {(experiences: Experience[]) => Experience[]} */
const sortExperiences = (experiences) => {
  return experiences.slice().sort((a, b) => {
    if (!a.to && !b.to) {
      // Experiences A and B are not finished, compare start date
      // @ts-ignore
      return new Date(b.from) - new Date(a.from);
    }
    if (!a.to && b.to) {
      // Experience A is not finished and B is, A goes before B
      return -1;
    }
    if (a.to && !b.to) {
      // Experience A is finished and B isn't, A goes after B
      return 1;
    }
    if (b.to === a.to) {
      // Experiences A and B have the same end date, compare the start date
      // @ts-ignore
      return new Date(b.from) - new Date(a.from);
    }
    // @ts-ignore
    return new Date(b.to) - new Date(a.to);
  });
};

/** @type {(experiences: Experience[]) => Experience[]} */
const getSortedCandidateExperiences = (experiences) => {
  return sortExperiences(experiences || []);
};

/** @type {(experiences: Experience[]) => Experience[]} */
const getCandidateCurrentExperiences = (experiences) => {
  return getSortedCandidateExperiences(experiences).filter((experience) => !experience.to);
};

/** @type {(candidateDetails: CandidateDetails) => number} */
export const getCandidateExperienceYears = (candidateDetails) => {
  const experiences = candidateDetails.experiences || [];
  const oneYear = 365 * 24 * 60 * 60 * 1000;
  // Sum the duration of all experiences
  const experienceTimeInMs = experiences.reduce((total, experience) => {
    const from = new Date(experience.from);
    const to = experience.to ? new Date(experience.to) : new Date();
    const duration = to.getTime() - from.getTime();
    return total + duration;
  }, 0);
  return Math.floor(experienceTimeInMs / oneYear);
};

/** @type {(candidateDetails: CandidateDetails) => string} */
const getCandidateDefaultTitle = (candidateDetails) => {
  if (candidateDetails.job && candidateDetails.job.name) {
    // If a job has been selected, use it as the candidate by default
    return candidateDetails.job.name;
  }
  // If no job has been selected, use candidate experiences to resolve a title
  const currentExperiences = getCandidateCurrentExperiences(candidateDetails.experiences);
  return currentExperiences.map((experience) => experience.title).join(", ");
};

/** @type {() => string} */
const getCurrentTitleLanguage = () => {
  return i18n.language.split("-")[0].toUpperCase();
};

/** @type {(candidateDetails: CandidateDetails) => string} */
export const getCandidateTitle = (candidateDetails) => {
  return (
    candidateDetails.customResumeTitle?.labels?.[getCurrentTitleLanguage()] ||
    // or retrive the potential fallback
    candidateDetails.customResumeTitle?.fallback ||
    // or retrieve the default title resolved from candidate work experiences or selected job
    getCandidateDefaultTitle(candidateDetails)
  );
};

/** @type {(driverLicences: DriverLicences) => string[]} */
export const getDrivingLicences = (driverLicences) => {
  const licences = [];
  if (driverLicences.hasLicenceA) {
    licences.push("A");
  }
  if (driverLicences.hasLicenceB) {
    licences.push("B");
  }
  if (driverLicences.hasLicenceC) {
    licences.push("C");
  }
  if (driverLicences.hasLicenceCE) {
    licences.push("CE");
  }
  if (driverLicences.hasLicenceC1) {
    licences.push("C1");
  }
  if (driverLicences.hasLicenceC1E) {
    licences.push("C1E");
  }
  if (driverLicences.hasLicenceD) {
    licences.push("D");
  }
  if (driverLicences.hasLicenceDE) {
    licences.push("DE");
  }
  if (driverLicences.hasLicenceD1) {
    licences.push("D1");
  }
  if (driverLicences.hasLicenceD1E) {
    licences.push("D1E");
  }
  return licences;
};

/**
 * @typedef Intelli7Score
 * @property {string} benchmark
 * @property {string} caption
 * @property {string} score
 */
/**
 * @typedef Intelli7Result
 * @property {{ [key: string]: Intelli7Score }} individual
 * @property {{ [key: string]: Intelli7Score }} relational
 * @property {{ [key: string]: Intelli7Score }} operational
 */
/**
 * @typedef Intelli7QuestionnaireResult
 * @property {Intelli7Result} result
 */
/** @type {(candidateId: string) => import("react-query").UseQueryResult<Intelli7QuestionnaireResult>} */
export const useCandidateQuestionnaireResultsQuery = (candidateId) => {
  const candidateAPI = useCandidateAPI();
  return useQuery(
    `api/questionnaire/intelli7/${candidateId}`,
    async () => {
      try {
        const result = await candidateAPI.get(`api/questionnaire/intelli7/${candidateId}`).json();
        return result;
      } catch (e) {
        if (e.name === "APIError") {
          if (e.message === "business:intelli7.results.not.found") {
            return null;
          }
        }
        throw e;
      }
    },
    {
      enabled: !!candidateId,
    }
  );
};

/** @type {(candidateId: string) => import("react-query").UseQueryResult<Intelli7QuestionnaireResult>} */
export const useUnauthenticatedCandidateQuestionnaireResultsQuery = (candidateId) => {
  const candidateAPI = useCandidateAPI();
  return useQuery(
    `api/questionnaire/intelli7/${candidateId}/unauthenticated`,
    async () => {
      try {
        const result = await candidateAPI.get(`api/questionnaire/intelli7/${candidateId}/unauthenticated`).json();
        return result;
      } catch (e) {
        if (e.name === "APIError") {
          if (e.message === "business:intelli7.results.not.found") {
            return null;
          }
        }
        throw e;
      }
    },
    {
      enabled: !!candidateId,
    }
  );
};

// Results are not named the same as the part they are associated with.
// This mapping translate the questionnaire part name to result key (e.g.: "intelli7" "part1" is "selfmanagement")
const questionnairePartToResultMapping = {
  part1: "individual",
  part2: "relational",
  part3: "operational",
};

/** @type {(questionnairePart: keyof questionnairePartToResultMapping) => string} */
const getQuestionnairePartResultKey = (questionnairePart) => {
  return questionnairePartToResultMapping[questionnairePart];
};

/**
 * @typedef QuestionnaireFormattedResult
 * @property {string} id
 * @property {string} title
 * @property {string} description
 * @property {number} score
 * @property {number | null} benchmark
 */

/** @type {(questionnaireResults: Intelli7QuestionnaireResult, questionnairePart: keyof questionnairePartToResultMapping, topResultCount?: number) => QuestionnaireFormattedResult[]} */
export const getTopQuestionnaireResults = (
  questionnaireResults,
  questionnairePart,
  topResultCount = 3
) => {
  // We check that we could find the questionnaire results
  if (!questionnaireResults || !questionnaireResults.result) {
    return null;
  }

  // Retrieve the result key associated with the current part
  const partResultKey = getQuestionnairePartResultKey(questionnairePart);

  const partResultBySkill = questionnaireResults.result[partResultKey];
  // Check that there are some results for this part
  if (!partResultBySkill) {
    return null;
  }

  const partResults = Object.entries(partResultBySkill).map(([skill, skillResult]) => ({
    id: skill,
    // Create translations keys from skill name
    title: `${skill}Title`,
    description: `${skill}Description`,
    // Convert score and benchmark to numbers
    score: parseInt(skillResult.score, 10),
    benchmark:
      // Benchmark might be "N/A" if no benchmark is available
      skillResult.benchmark === "N/A" ? null : parseInt(skillResult.benchmark, 10),
  }));

  // Sort results by descending score
  partResults.sort((skillResultA, skillResultB) => {
    return skillResultB.score - skillResultA.score;
  });

  // We only display the top N results on this screen
  const topResults = partResults.slice(0, topResultCount);

  return topResults;
};

/** @type {(searchParams?: import("../shared/useAPI").SearchParams) => import("react-query").UseQueryResult<import("../shared/useAPI").PageableResult<Candidate>>} */
export const useBestCandidates = (searchParams) => {
  const api = useCandidateAPI();
  return useQuery(
    ["api/domain/candidates/best", searchParams],
    async () => api.get("api/domain/candidates/best", { searchParams }).json(),
    {
      keepPreviousData: true,
    }
  );
};

/** @type {(searchParams?: import("../shared/useAPI").SearchParams) => import("react-query").UseQueryResult<import("../shared/useAPI").PageableResult<Candidate>>} */
export const useNewCountCandidates = (searchParams) => {
  const api = useCandidateAPI();
  return useQuery(
    ["api/domain/candidates/new/count", searchParams],
    async () => api.get("api/domain/candidates/new/count", { searchParams }).json(),
    {
      keepPreviousData: true,
    }
  );
};

export const useCandidateUnreadMessagesChatCountQuery = (candidateUuid) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `api/chat/countmessages/${candidateUuid}/unread`,
    queryFn: async () => api.get(`api/chat/countmessages/${candidateUuid}/unread`).json(),
    enabled: !!candidateUuid
  });
};
