import { ErrorBoundary } from "@sentry/react";
import { lazy, Suspense, useEffect } from "react";
// import ReactGA from "react-ga";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useAuth, useRole } from "./auth/AuthContext";
import AuthLayout from "./auth/AuthLayout";
import { Home } from "./homePage/Home";
import { Templates } from "./homePage/Templates";
import { Welcome } from "./Welcome";
import { useJobAdsQuery } from "./job-ads/JobAdAPI";
import { ErrorFallback } from "./shared/ErrorFallback";
import { Layout } from "./shared/Layout";
import Companies from "./school/Companies";

const NotFoundRoute = lazy(() => import("./not-found/NotFoundRoute"));
const LoginRoute = lazy(() => import("./auth/LoginRoute"));
const RegisterRoute = lazy(() => import("./auth/RegisterRoute"));
const ForgotPasswordRoute = lazy(() => import("./auth/ForgotPasswordRoute"));
const ResetPasswordRoute = lazy(() => import("./auth/ResetPasswordRoute"));
const ConfirmRoute = lazy(() => import("./auth/ConfirmRoute"));
const JobAdRoutes = lazy(() => import("./job-ads/JobAdRoutes"));
const StudentCandidateDetails = lazy(() => import("./candidate-search/StudentCandidateDetails"));
const JobAdSchoolRoutes = lazy(() => import("./job-ads-school/JobAdRoutes"));
const CandidateSearchRoutes = lazy(() => import("./candidate-search/candidates/CandidateSearchRoutes"));
const PoolSearchRoutes = lazy(() => import("./candidate-search/candidates-pool/PoolSearchRoutes"));
const SchoolPoolSearchRoutes = lazy(() => import("./candidate-search/candidates-school-pool/SchoolPoolSearchRoutes"));
const Students = lazy(() => import("./candidate-search/Students"));
const OrganizationsAdmin = lazy(() => import("./admin/Organizations"));
const SectorsAdmin = lazy(() => import("./admin/Sectors"));
const DomainsAdmin = lazy(() => import("./admin/Domains"));
const SpecialitiesAdmin = lazy(() => import("./admin/Specialities"));
const JobsAdmin = lazy(() => import("./admin/Jobs"));
const OrganizationAdminDetails = lazy(() => import("./admin/OrganizationDetails"));
const OrganizationAdminCreate = lazy(() => import("./admin/OrganizationCreate"));
const EnrollCompany = lazy(() => import("./school/EnrollCompany"));
const ChatRoutes = lazy(() => import("./chat/ChatRoutes"));
const DataRoutes = lazy(() => import("./data/DataRoutes"));
const OrganizationSettingsRoute = lazy(() => import("./settings/OrganizationSettingsRoute"));
const UserSettingsRoute = lazy(() => import("./settings/UserSettingsRoute"));
const PricingRoute = lazy(() => import("./pricing/PricingRoute"));
const ConfirmedAccount = lazy(() => import("./auth/ConfirmedAccount"));


const UnauthenticatedRoutes = () => {
  const location = useLocation();
  let organizationContext = "CLASSICAL";
  if (['/metro-register', '/metro-login', '/metro-forgot-password'].includes(location.pathname)) {
    organizationContext = "METRO";
  } else if (['/gusty-register', '/gusty-login', '/gusty-forgot-password'].includes(location.pathname)) {
    organizationContext = "GUSTY";
  }
  return (
    <AuthLayout organizationContext={organizationContext}>
      <ErrorBoundary fallback={ErrorFallback}>
        <Suspense fallback={null}>
          <Switch>
            {/* <Route path="/register" component={RegisterRoute} exact />
            <Route path="/login" component={LoginRoute} exact />
            <Route path="/forgot-password" component={ForgotPasswordRoute} exact /> */}
            <Route path="/register" exact render={(props) => <RegisterRoute {...props} organizationContext={organizationContext} />} />
            <Route path="/login" exact render={(props) => <LoginRoute {...props} organizationContext={organizationContext} />} />
            <Route path="/forgot-password" exact render={(props) => <ForgotPasswordRoute {...props} organizationContext={organizationContext} />} />
            <Route path="/reset-password" component={ResetPasswordRoute} exact />
            <Route path="/confirm" component={ConfirmRoute} exact />
            <Route path="/confirmedAccount" component={LoginRoute} exact />
            <Route path="/partnership" component={LoginRoute} exact />
            <Route path="/metro-register" exact render={(props) => <RegisterRoute {...props} organizationContext={organizationContext} />} />
            <Route path="/metro-login" exact render={(props) => <LoginRoute {...props} organizationContext={organizationContext} />} />
            <Route path="/metro-forgot-password" exact render={(props) => <ForgotPasswordRoute {...props} organizationContext={organizationContext} />} />
            <Route path="/gusty-register" exact render={(props) => <RegisterRoute {...props} organizationContext={organizationContext} />} />
            <Route path="/gusty-login" exact render={(props) => <LoginRoute {...props} organizationContext={organizationContext} />} />
            <Route path="/gusty-forgot-password" exact render={(props) => <ForgotPasswordRoute {...props} organizationContext={organizationContext} />} />
            <Route path="*">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </AuthLayout>
  )
};

const AuthenticatedRoutes = ({ jobAdsCreatedByUser, organizationType }) => (
  <Layout>
    <ErrorBoundary fallback={ErrorFallback}>
      <Suspense fallback={null}>
        <Switch>
          <Route path="/" exact>
            {jobAdsCreatedByUser === 0 ? <Redirect to="/welcome" /> : <Redirect to="/home" />}
          </Route>
          <Route path="/register" exact>
            <Redirect to="/job-ads" />
          </Route>
          <Route path="/metro-register" exact>
            {jobAdsCreatedByUser === 0 ? <Redirect to="/welcome" /> : <Redirect to="/home" />}
          </Route>
          <Route path="/confirm" exact>
            {jobAdsCreatedByUser === 0 ? <Redirect to="/welcome" /> : <Redirect to="/home" />}
          </Route>
          <Route path="/templates" component={Templates} />
          <Route path="/job-ads" component={JobAdRoutes} />
          <Route path="/job-ads-school" component={JobAdSchoolRoutes} />
          <Route path="/candidate-search" component={CandidateSearchRoutes} />
          <Route path="/candidate-search-pool" component={PoolSearchRoutes} />
          <Route path="/candidate-school-search-pool" component={SchoolPoolSearchRoutes} />
          <Route path="/students" component={Students} />
          <Route path="/companies" component={Companies} />
          <Route path="/chat" component={ChatRoutes} />
          <Route path="/data" component={DataRoutes} />
          <Route path="/settings" exact>
            <Redirect to="/organization-settings" />
          </Route>
          <Route path="/organization-settings" component={OrganizationSettingsRoute} />
          <Route path="/my-settings" component={UserSettingsRoute} />
          <Route exact path="/pricing" component={PricingRoute} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/welcome" component={Welcome} />
          <Route path="/partnership" component={EnrollCompany}>
            {organizationType === "SCHOOL" && <Redirect to="/home" />}
          </Route>
          <Route path="/confirmedAccount" exact component={ConfirmedAccount} />
          <Route path="/admin/organizations" component={OrganizationsAdmin} />
          <Route path="/admin/organizations-create" component={OrganizationAdminCreate} />
          <Route path="/admin/sectors" component={SectorsAdmin} />
          <Route path="/admin/domains/:sectorId" component={DomainsAdmin} />
          <Route path="/admin/specialities/:sectorId/:domainId" component={SpecialitiesAdmin} />
          <Route path="/admin/jobs/:sectorId/:domainId/:specialityId" component={JobsAdmin} />

          <Route path="*" component={NotFoundRoute} exact />
        </Switch>
      </Suspense>
    </ErrorBoundary>

    <ErrorBoundary fallback={ErrorFallback}>
      <Suspense fallback={null}>
        <Route path="/students/:candidateId" component={StudentCandidateDetails} />
        <Route path="/admin/organizations/:organizationUuid" component={OrganizationAdminDetails} />
      </Suspense>
    </ErrorBoundary>
  </Layout>
);

export const Routes = () => {
  const { accessToken } = useAuth();
  const { data, status } = useJobAdsQuery();
  const jobAdsCreatedByUser = data?.totalElements;
  const organizationType = localStorage.getItem("organizationType");
  const location = useLocation();
  const role = useRole();

  // useEffect(() => {
  //   if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE) {
  //     ReactGA.pageview(location.pathname + location.search);
  //   }
  // }, [location]);

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE) {
      setTimeout(() => {
        if (window.gtag) {
          window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE, {
            page_path: location.pathname
          });
        }
      }, 1000);
    }
  }, [location]);

  return accessToken ? (
    <>
      {
        "JOBOOK_ADMIN" === role ? (
          <AuthenticatedRoutes
            jobAdsCreatedByUser={jobAdsCreatedByUser}
            organizationType={organizationType}
          />
        ) : (
          <>
            {
              status === "success" && (
                <AuthenticatedRoutes
                  jobAdsCreatedByUser={jobAdsCreatedByUser}
                  organizationType={organizationType}
                />
              )}
            {
              status === "error" && (
                <AuthenticatedRoutes
                  jobAdsCreatedByUser={0}
                  organizationType={organizationType}
                />
              )}
          </>
        )
      }
    </>
  ) : (
    <UnauthenticatedRoutes />
  );
};
