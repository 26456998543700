/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "twin.macro";
import { useJobAdsQuery } from "./job-ads/JobAdAPI";
import { useOrganizationQuery } from "./settings/OrganizationAPI";
import { PrimaryButton } from "./shared/Button";
import { useAuth } from "./auth/AuthContext";

export const Welcome = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  // Get all the jobAds (MULTIPOSTED and SCHOOL)
  const { data } = useJobAdsQuery();
  const jobAdsCreatedByUser = data?.totalElements;
  const { data: dataOrganization } = useOrganizationQuery();
  return (
    <main tw="flex-1 max-h-screen overflow-y-auto sm:pt-24">
      <h1 tw="text-center text-2xl font-extrabold tracking-tight text-gray-800 pt-8 sm:pt-0 sm:text-4xl">
        {t("home.title")}
      </h1>
      {dataOrganization?.organizationCampaign === "SPECIAL_OFFER" &&
        jobAdsCreatedByUser === 0 &&
        dataOrganization?.jobAdTokens > 0 && (
          <div tw="flex justify-center p-4 mt-5">
            <div tw="bg-green-400 text-white text-center p-8 mt-5 rounded-xl">
              {t("home.campaign-offer")}
            </div>
          </div>
        )}
      {process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" && (
        <div tw="flex items-center justify-center pt-4 sm:pt-16">
          <PrimaryButton as={Link} to={("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? "/templates" : "/job-ads/new"}>
            {t("shared.create-jobad")}
          </PrimaryButton>
        </div>
      )}
      {/* Display create add for hupso, but with link to create school ad */}
      {process.env.REACT_APP_APPLICATION_DOMAIN === "hupso" && (
        <div tw="flex items-center justify-center pt-4 sm:pt-16">
          <PrimaryButton as={Link} to="/job-ads-school/new">
            {t("shared.create-jobad")}
          </PrimaryButton>
        </div>
      )}
      <section tw="flex flex-wrap justify-center mx-auto gap-8 mt-8 sm:mt-16">
        <div aria-hidden="true" tw="hidden absolute bg-gray-50 w-full h-6 bottom-0 lg:block" />
        <div tw="flex flex-col items-center max-w-xs sm:max-w-sm">
          {
            ("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? (
              <img
                width="290"
                height="290"
                src="https://static.jobfirst.io/welcome/img/welcome-metro-offre.png"
                alt=""
                sizes="(max-width: 290px) 100vw, 290px"
                data-ll-status="loaded"
              />
            ) : (
              <figure>
                <img
                  width="250"
                  height="250"
                  src="/homeIcons/echange_recruteur_candidat.svg
              "
                  alt=""
                  sizes="(max-width: 250px) 100vw, 250px"
                  data-ll-status="loaded"
                />
                <noscript>
                  <img
                    width="250"
                    height="250"
                    src="/homeIcons/jobook_recrutement_competences_personnalite_intelligence_artificielle-1.svg
                "
                    alt=""
                    sizes="(max-width: 250px) 100vw, 250px"
                  />
                </noscript>
              </figure>
            )
          }
          <div tw="flex flex-col items-center justify-center mt-4">
            <h3 tw="text-lg leading-6 font-semibold text-gray-900 text-center sm:h-16">
              {("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? t("home.welcome-title-1-metro") : t("home.welcome-title-1")}
            </h3>
            <p tw="text-sm leading-6 text-gray-500 text-center mt-2 sm:mt-0">
              {("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? t("home.welcome-text-1-metro") : t("home.welcome-text-1")}
            </p>
          </div>
        </div>

        <div tw="flex flex-col items-center max-w-xs sm:max-w-sm">
          {
            ("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? (
              <img
                width="290"
                height="290"
                src="https://static.jobfirst.io/welcome/img/welcome-metro-selection.png"
                alt=""
                sizes="(max-width: 290px) 100vw, 290px"
                data-ll-status="loaded"
              />
            ) : (
              <figure>
                <img
                  width="250"
                  height="250"
                  src="/homeIcons/jobook_candidat_matching.svg"
                  alt=""
                  sizes="(max-width: 250px) 100vw, 250px"
                  data-ll-status="loaded"
                />
                <noscript>
                  <img
                    width="250"
                    height="250"
                    src="/homeIcons/jobook_candidat_matching.svg"
                    alt=""
                    sizes="(max-width: 250px) 100vw, 250px"
                  />
                </noscript>
              </figure>
            )
          }
          <div tw="flex flex-col items-center justify-center mt-4">
            <h3 tw="text-lg leading-6 font-semibold text-gray-900 text-center sm:h-16">
              {("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? t("home.welcome-title-2-metro") : t("home.welcome-title-2")}
            </h3>
            <p tw="text-sm leading-6 text-gray-500 text-center mt-2 sm:mt-0">
              {t("home.welcome-text-2")}
            </p>
          </div>
        </div>

        <div tw="flex flex-col items-center max-w-xs sm:max-w-sm">
          {
            ("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? (
              <img
                width="290"
                height="290"
                src="https://static.jobfirst.io/welcome/img/welcome-metro-message.png"
                alt=""
                sizes="(max-width: 290px) 100vw, 290px"
                data-ll-status="loaded"
              />
            ) : (
              <figure>
                <img
                  width="250"
                  height="250"
                  src="/homeIcons/jobook_recrutement_competences_personnalite_intelligence_artificielle-1.svg
              "
                  alt=""
                  sizes="(max-width: 250px) 100vw, 250px"
                  data-ll-status="loaded"
                />
                <noscript>
                  <img
                    width="250"
                    height="250"
                    src="/homeIcons/jobook_recrutement_competences_personnalite_intelligence_artificielle-1.svg
                "
                    alt=""
                    sizes="(max-width: 250px) 100vw, 250px"
                  />
                </noscript>
              </figure>
            )
          }
          <div tw="flex flex-col items-center justify-center mt-4">
            <h3 tw="text-lg leading-6 font-semibold text-gray-900 text-center sm:h-16">
              {("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? t("home.welcome-title-3-metro") : t("home.welcome-title-3")}
            </h3>
            <p tw="text-sm leading-6 text-gray-500 text-center mt-2 sm:mt-0">
              {("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? t("home.welcome-text-3-metro") : t("home.welcome-text-3")}
            </p>
          </div>
        </div>
      </section>
      {process.env.REACT_APP_CLASSICAL_JOBAD_DOMAIN === "true" && (
        <div tw="flex items-center justify-center mt-16 pb-16">
          <PrimaryButton as={Link} to={("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? "/templates" : "/job-ads/new"}>
            {t("shared.create-jobad")}
          </PrimaryButton>
        </div>
      )}
      {/* Display create add for hupso, but with link to create school ad */}
      {process.env.REACT_APP_APPLICATION_DOMAIN === "hupso" && (
        <div tw="flex items-center justify-center mt-16 pb-16">
          <PrimaryButton as={Link} to="/job-ads-school/new">
            {t("shared.create-jobad")}
          </PrimaryButton>
        </div>
      )}
    </main>
  );
};
