/** @jsxImportSource @emotion/react */
import { ClipboardCheckIcon, ClipboardIcon, LinkIcon } from "@heroicons/react/outline";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import useCopyClipboard from "react-use-clipboard";
import "twin.macro";
import { getStudentEnrollURL, getCompanyEnrollURL, useStudentPoolUuid } from "../school/SchoolAPI";
import { useOrganizationQuery } from "../settings/OrganizationAPI";
import { useAuth } from "../auth/AuthContext";
import { Button, PrimaryOppositeButton } from "../shared/Button";

export const ContactBlock = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <div tw="flex flex-col flex-none bg-primary-600 justify-around gap-4 p-8 rounded-md w-full xl:w-4/12">
      <div tw="flex flex-col items-center gap-4 xl:items-baseline">
        <span tw="text-base font-medium text-white whitespace-normal">{("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? t("home.phrase-one-metro") : t("home.phrase-one")}</span>
        <span tw="text-base font-medium text-white whitespace-normal">{("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? t("home.phrase-two-metro") : t("home.phrase-two")}</span>
        <span tw="text-base font-medium text-white whitespace-normal">{("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? t("home.phrase-three-metro") : t("home.phrase-three")}</span>
      </div>

      <div tw="flex flex-col items-center">
        <span tw="text-base font-medium text-white whitespace-normal">{t("home.need-help")}</span>

        <PrimaryOppositeButton
          tw="mt-2"
          as="a"
          href={("METRO" === user?.organizationPlan || "GUSTY" === user?.organizationPlan ) ? "https://jobfirst.io/contact-metro-offres/" :  "https://jobfirst.io/contact-offres/"}
          rel="noopener noreferrer"
          target="_blank">
          {t("pricing.contact-us")}
        </PrimaryOppositeButton>
        {/* <Button tw="mt-2 shadow-md hover:shadow-lg">
          <a
            href="mailto:support@jobfirst.io"
            title="support@jobfirst.io"
            tw="text-base font-medium whitespace-normal"
          >
            {t("home.contact-us")}
          </a>
        </Button> */}
      </div>
    </div>
  );
};

export const ContactBlockSchool = () => {
  const { t } = useTranslation();
  const { status: mySchoolStatus, data: mySchoolData } = useOrganizationQuery();
  const { status: studentPoolStatus, data: studentPool } = useStudentPoolUuid();
  const mySchoolDataUuid = mySchoolStatus === "success" && mySchoolData.uuid;
  const mySchoolDataUniqueUrlName =
    mySchoolStatus === "success" && mySchoolData.organizationUniqueUrlName;

  const studentPoolUuid = studentPoolStatus === "success" && studentPool.uuid;

  return (
    <div tw="flex flex-col flex-none bg-primary-600 justify-around gap-4 p-8 rounded-md w-full xl:w-4/12">
      <div tw="flex flex-col items-center gap-4">
        <span tw="text-base font-medium text-white whitespace-normal">{t("home.share-links")}</span>
        <JobAdURLCopySchool
          tw="mt-4"
          uuid={studentPoolUuid}
          url={process.env.REACT_APP_JOBOOK_APP_URL}
          schoolName={mySchoolDataUniqueUrlName}
          linkStudent={true}
          linkName={t("schoolOrganization.pupil")}
        />
      </div>

      <div tw="flex flex-col items-center gap-4 ">
        <JobAdURLCopySchool
          tw="mt-4"
          uuid={mySchoolDataUuid}
          url={process.env.REACT_APP_DASHBOARD_URL}
          schoolName={mySchoolDataUniqueUrlName}
          linkStudent={false}
          linkName={t("schoolOrganization.recruiter")}
        />
      </div>

      <div tw="flex flex-col items-center">
        <span tw="text-base font-medium text-white whitespace-normal">{t("home.need-help")}</span>

        <Button tw="mt-2 shadow-md hover:shadow-lg">
          <a
            href="mailto:support@jobfirst.io"
            title="support@jobfirst.io"
            tw="text-base font-medium whitespace-normal"
          >
            {t("home.contact-us")}
          </a>
        </Button>
      </div>
    </div>
  );
};

export const JobAdURLCopySchool = ({ uuid, linkName, linkStudent, jobAd, schoolName, url, ...props }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const uuiddURL = linkStudent ? getStudentEnrollURL({ url, uuid, schoolName }) : getCompanyEnrollURL({ url, uuid, schoolName });
  const [isCopied, setCopied] = useCopyClipboard(uuiddURL, { successDuration: 1000 });
  return (
    <div tw="flex rounded-md shadow-sm" {...props}>
      <div tw="relative flex items-stretch flex-grow focus-within:z-10">
        <div tw="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <LinkIcon tw="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          ref={inputRef}
          type="text"
          value={uuiddURL}
          readOnly
          aria-label={t("job-ads.job-ad-url")}
          tw="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-none rounded-l-md pl-10 text-sm border-gray-300 text-gray-500"
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.setSelectionRange(0, inputRef.current.value.length);
            }
          }}
        />
      </div>
      <button
        type="button"
        onClick={setCopied}
        tw="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
      >
        {isCopied ? (
          <>
            <ClipboardCheckIcon tw="flex-shrink-0 h-5 w-5 text-gray-400" />
            <span tw="whitespace-nowrap">{t("shared.copied")}</span>
          </>
        ) : (
          <>
            <ClipboardIcon tw="flex-shrink-0 h-5 w-5 text-gray-400" />
            <span tw="whitespace-nowrap">{linkName}</span>
          </>
        )}
      </button>
    </div>
  );
};
