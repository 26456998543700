/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import "twin.macro";
import { EmptyState, EmptyStateDescription } from "../shared/EmptyState";
import { useFormatDate } from "../shared/useFormatDate";

/** @type {React.FC<{ experience: import("./CandidateAPI").Experience }>} */
const ExperienceListItem = ({ experience }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate({ dateStyle: "medium" });
  return (
    <li tw="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm">
      <p tw="text-sm font-medium text-gray-900">{experience.title}</p>
      <p tw="text-sm text-gray-500">
        {experience.company} - {formatDate(new Date(experience.from))} -{" "}
        {experience.to ? formatDate(new Date(experience.to)) : t("candidates.today")}
      </p>
    </li>
  );
};

const sortExperiences = (a, b) => {
  if (a.to === null && b.to !== null) return -1;
  if (a.to !== null && b.to === null) return 1;
  if (a.to === null && b.to === null) {
    return b.from.localeCompare(a.from);
  }
  return b.to.localeCompare(a.to);
}

/** @type {React.FC<{ experiences?: import("./CandidateAPI").Experience[] }>} */
export const ExperienceList = ({ experiences, empty }) => {
  const isEmpty = !experiences || experiences.length === 0;
  return isEmpty ? (
    <EmptyState tw="bg-gray-100 rounded-lg">
      <EmptyStateDescription tw="mt-0">{empty}</EmptyStateDescription>
    </EmptyState>
  ) : (
    <ul tw="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {experiences.sort(sortExperiences).map((experience) => (
        <ExperienceListItem key={experience.uuid} experience={experience} />
      ))}
    </ul>
  );
};
